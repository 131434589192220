import styled, { css } from "styled-components";
import { colors } from "../../styles/colors";
import { heading } from "../../styles/ui";
import * as buttons from "../../styles/buttons";
import { mqs } from "../../styles/mqs";

export const Container = styled.div`
  background-color: ${colors.biscay};
  padding: 100px 0 80px;
  color: ${colors.white};
`;

export const ContainerInner = styled.div`
  margin: 0 auto;
  padding: 0 24px;
  max-width: 1168px;
  width: 100%;
`;

export const Content = styled.div`
  display: grid;
  ${mqs.md} {
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
  }
`;

export const Heading = styled.h2`
  margin-bottom: 24px;
  ${heading};
  span {
    color: ${colors.curiousBlue};
  }
`;

export const ImgContainer = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: -24px;
  ${mqs.md} {
    width: 100%;
    padding-left: 50px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Form = styled.div`
  position: relative;
  display: grid;
  grid-gap: 18px;
  align-items: flex-start;
  grid-template-rows: auto;
  grid-template-areas:
    "name"
    "email"
    "message";
  ${mqs.md} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "name email"
      "message message";
  }
`;

const inputStyles = css`
  background: rgba(255, 255, 255, 0.2);
  padding: 16px;
  border: 0;
  outline: 0;
  font: inherit;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.4);
  ::placeholder {
    font: inherit;
    color: rgba(255, 255, 255, 0.4);
  }
`;

export const NameField = styled.div`
  grid-area: name;
  input {
    width: 100%;
    ${inputStyles};
  }
`;

export const EmailField = styled.div`
  grid-area: email;
  input {
    width: 100%;
    ${inputStyles};
  }
`;

export const MessageField = styled.div`
  grid-area: message;
  textarea {
    width: 100%;
    height: 145px;
    ${inputStyles};
  }
`;

export const SubmitContainer = styled.div`
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: start;
  margin: 30px 0;
`;

export const Submit = styled.button`
  ${buttons.primary};
  width: 100%;
  ${mqs.md} {
    width: auto;
  }
`;

export const Error = styled.p`
  margin-top: 6px;
  color: ${colors.sandrift};
  font-size: 12px;
`;

export const Address = styled.p`
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 54px;
  ${mqs.md} {
    margin-bottom: 0;
  }
`;

export const MapLink = styled.a`
  text-decoration: none;
  color: ${colors.curiousBlue};
`;

export const EmailLink = styled.a`
  text-decoration: none;
  color: ${colors.white};
`;

export const FormError = styled.p`
  margin-bottom: 18px;
  color: red;
`;

export const Success = styled.p``;
