import React from "react";

export const LeftArrow = () => (
  <svg width="22px" height="13px" viewBox="0 0 22 13" version="1.1">
    <g stroke="none" strokeWidth="1">
      <g
        transform="translate(11.000000, 6.500000) scale(-1, 1) translate(-11.000000, -6.500000) "
        fill="#3E92CC"
      >
        <path d="M0,0 L22,6.5 L0,13 L7,6.5 L0,0 Z M17.7,6.5 L8.9,6.5 L4.8,10.3 L17.7,6.5 Z"></path>
      </g>
    </g>
  </svg>
);
