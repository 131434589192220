import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import {
  Container,
  ClocksAnim,
  ContainerInner,
  Content,
  Left,
  Right,
  Eyelash,
  EyelashAnim,
  Heading,
  HeadingAnim,
  StyledImg,
  ImgAnim,
} from "./UI/HeroUI";
import { Clock } from "./Clock";
import Img from "gatsby-image";
import { useSpring, useTrail } from "react-spring";
import { ScrollIndicator } from "./ScrollIndicator";

const Line1 = () => (
  <>
    Invest in <span>growth.</span>
    <br />
  </>
);
const Line2 = () => (
  <>
    Invest in <span>relationships.</span>
    <br />
  </>
);
const Line3 = () => (
  <>
    Invest in <span>success.</span>
  </>
);

export const Hero = () => {
  const [isReady, setIsReady] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "ksv-hero.jpg" }) {
        childImageSharp {
          fluid(quality: 85, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  const headings = [<Line1 />, <Line2 />, <Line3 />];
  const opacityAnim = useSpring({ opacity: isReady ? 1 : 0 });
  const headingAnim = useTrail(headings.length, {
    from: { opacity: 0, transform: "translate3d(0, 40px, 0)" },
    transform: "translate3d(0, 0, 0)",
    opacity: 1,
  });
  const imgAnim = useSpring({
    transform: isReady ? "scale(1.3)" : "scale(1)",
    config: { duration: 10000 },
  });
  useEffect(() => {
    setIsReady(true);
  }, []);
  return (
    <Container id="hero">
      <ContainerInner>
        <ClocksAnim style={opacityAnim}>
          <Clock location="boston" />
          <Clock location="london" />
          <Clock location="seoul" />
        </ClocksAnim>
        <Content>
          <Left>
            <Eyelash>
              <EyelashAnim style={opacityAnim}>We Are KSV Global</EyelashAnim>
            </Eyelash>
            <Heading>
              {headingAnim.map((props, i) => (
                <HeadingAnim key={`heading-line-${i}`} style={props}>
                  {headings[i]}
                </HeadingAnim>
              ))}
            </Heading>
          </Left>
          <Right>
            <StyledImg>
              <ImgAnim style={imgAnim}>
                <Img fluid={data.file.childImageSharp.fluid} />
              </ImgAnim>
            </StyledImg>
          </Right>
        </Content>
      </ContainerInner>
      <ScrollIndicator />
    </Container>
  );
};
