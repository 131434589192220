import { useState, useEffect } from "react";

type ScrollPosition = {
  x: number;
  y: number;
};

const isBrowser = typeof window !== `undefined`;

const getScrollPosition = () =>
  isBrowser ? { x: window.pageXOffset, y: window.pageYOffset } : { x: 0, y: 0 };

export const useScrollPosition = () => {
  const [position, setScrollPosition] = useState<ScrollPosition>(
    getScrollPosition()
  );

  useEffect(() => {
    let requestRunning: number | null = null;
    const handleScroll = () => {
      if (isBrowser && requestRunning === null) {
        requestRunning = window.requestAnimationFrame(() => {
          setScrollPosition(getScrollPosition());
          requestRunning = null;
        });
      }
    };

    if (isBrowser) {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return position;
};
