import React from "react";
import {
  Container,
  ContainerInner,
  StyledImg,
  Heading,
  Eyelash,
  Content,
  Copy,
  Intro,
  ContentInner,
  ImgContainer
} from "./UI/WhoWeAreUI";
import { useStaticQuery, graphql } from "gatsby";
import { AnimFade } from "./AnimFade";
import { SectionDots } from "./SectionDots";

export const WhoWeAre = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "ksv-who-we-are.png" }) {
        childImageSharp {
          fluid(quality: 85, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  return (
    <Container>
      <ContainerInner>
        <Content>
          <ImgContainer>
            <AnimFade direction="right">
              <StyledImg fluid={data.file.childImageSharp.fluid} />
            </AnimFade>
          </ImgContainer>
          <ContentInner>
            <Intro>
              <AnimFade direction="up">
                <Eyelash>Who we are</Eyelash>
                <Heading>
                  We partner with entrepreneurs
                  <br />
                  who are passionate about
                  <br />
                  <span>transformative growth.</span>
                </Heading>
              </AnimFade>
            </Intro>
            <AnimFade direction="up">
              <Copy>
                KSV Global is a growth stage investment firm committed to
                relationship and company building. We channel our history of
                family office values and widespread institutional expertise into
                measurable mutual success in every investment we make.
              </Copy>
            </AnimFade>
          </ContentInner>
        </Content>
      </ContainerInner>
      <SectionDots />
    </Container>
  );
};
