import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import {
  Address,
  Container,
  ContainerInner,
  Form,
  Heading,
  Content,
  ImgContainer,
  NameField,
  EmailField,
  MessageField,
  Submit,
  SubmitContainer,
  Error,
  FormError,
  Success,
  MapLink,
  EmailLink,
} from "./UI/ContactUI";
import Img from "gatsby-image";
import * as Yup from "yup";
import { Formik, Field, Form as FormikForm } from "formik";
import * as qs from "query-string";
import axios from "axios";
import { AnimFade } from "./AnimFade";

const SignupSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  name: Yup.string().required("Name is required"),
  message: Yup.string().required("Message is required"),
});

export const Contact = () => {
  const [loading, setLoading] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "ksv-contact.jpg" }) {
        childImageSharp {
          fluid(quality: 85, maxWidth: 910) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  return (
    <Container>
      <ContainerInner>
        <AnimFade direction="up">
          <Heading>
            LP? Founder? <span>Let’s chat.</span>
          </Heading>
        </AnimFade>
        <Content>
          <AnimFade direction="right">
            <Formik
              initialValues={{ email: "", name: "", message: "" }}
              initialStatus={{ sent: false, focused: false, errored: false }}
              validationSchema={SignupSchema}
              onSubmit={async (values, { setStatus, resetForm }) => {
                setLoading(true);
                await new Promise((resolve) => setTimeout(resolve, 600));
                try {
                  axios({
                    url: "/",
                    method: "POST",
                    headers: {
                      "Content-Type": "application/x-www-form-urlencoded",
                    },
                    data: qs.stringify({
                      "form-name": "KSV-Contact",
                      ...values,
                    }),
                  })
                    .then(() => {
                      resetForm();
                      setStatus({ sent: true, errored: false });
                      setTimeout(() => {
                        setStatus({ sent: false });
                      }, 2500);
                    })
                    .catch(() => {
                      setStatus({ errored: true });
                    });
                  setLoading(false);
                } catch (e) {
                  setLoading(false);
                }
              }}
            >
              {({ handleBlur, touched, errors, status, setStatus }) => {
                const onFocus = () => {
                  setStatus({ ...status, focused: true });
                };
                const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
                  setStatus({ ...status, focused: false });
                  handleBlur(event);
                };
                return (
                  <FormikForm
                    translate=""
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    name="KSV-Contact"
                  >
                    <input type="hidden" name="form-name" value="KSV-Contact" />
                    <input type="hidden" name="name" value="name" />
                    <input type="hidden" name="email" value="email" />
                    {status.errored && (
                      <FormError>
                        Error submitting form. Please try again.
                      </FormError>
                    )}
                    <Form>
                      <NameField>
                        <Field
                          placeholder="Name"
                          as="input"
                          name="name"
                          onFocus={onFocus}
                          onBlur={onBlur}
                          autoComplete="off"
                        />
                        {touched.name && errors.name && (
                          <Error>{errors.name}</Error>
                        )}
                      </NameField>
                      <EmailField>
                        <Field
                          placeholder="Email Address"
                          type="email"
                          name="email"
                          onFocus={onFocus}
                          onBlur={onBlur}
                          autoComplete="off"
                        />
                        {touched.email && errors.email && (
                          <Error>{errors.email}</Error>
                        )}
                      </EmailField>
                      <MessageField>
                        <Field
                          placeholder="Message"
                          as="textarea"
                          name="message"
                          onFocus={onFocus}
                          onBlur={onBlur}
                          autoComplete="off"
                        />
                        {touched.message && errors.message && (
                          <Error>{errors.message}</Error>
                        )}
                      </MessageField>
                    </Form>
                    <SubmitContainer>
                      {!status.sent && (
                        <Submit type="submit">
                          {loading ? "Submitting..." : "Submit"}
                        </Submit>
                      )}
                      {status.sent && (
                        <Success>
                          Thanks for getting in contact with us. We’ll reach
                          back out to you shortly.
                        </Success>
                      )}
                    </SubmitContainer>
                  </FormikForm>
                );
              }}
            </Formik>
            <Address>
              222 Lakeview Avenue, Suite 800
              <br />
              West Palm Beach, FL 33401 |{" "}
              <MapLink
                href="https://www.google.com/maps/place/222+Lakeview+Ave+%23+800,+West+Palm+Beach,+FL+33401/@26.705776,-80.0535342,17z/data=!3m1!4b1!4m6!3m5!1s0x88d8d68ad4ca791b:0x34a787ca618f26eb!8m2!3d26.7057712!4d-80.0509593!16s%2Fg%2F11qz9hzzwp?entry=ttu"
                target="blank"
              >
                View Map
              </MapLink>
              <br />
              <EmailLink href="mailto:info@ksvglobal.com">
                info@ksvglobal.com
              </EmailLink>
            </Address>
          </AnimFade>
          <ImgContainer>
            <AnimFade direction="left">
              <Img fluid={data.file.childImageSharp.fluid} />
            </AnimFade>
          </ImgContainer>
        </Content>
      </ContainerInner>
    </Container>
  );
};
