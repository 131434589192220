import React, { useState } from "react";
import {
  Container,
  ContainerInner,
  Eyelash,
  Heading,
  Copy,
  Header,
  Member,
  Members,
  MemberImg,
  MemberName,
  MemberPosition,
  MemberData,
} from "./UI/TheTeamUI";
import { useStaticQuery, graphql } from "gatsby";
import { AnimFade } from "./AnimFade";
import { ActiveMember } from "../components/ActiveMember";
import { TeamMember } from "../typings/sections";
import { SectionDots } from "./SectionDots";

export const TheTeam = () => {
  const [activeMember, setActiveMember] = useState<TeamMember | null>(null);
  const images = useStaticQuery(graphql`
    query {
      dan: file(relativePath: { eq: "ksv-dan.jpg" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 264) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      jon: file(relativePath: { eq: "ksv-jon.jpg" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 264) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      spencer: file(relativePath: { eq: "ksv-spencer.jpg" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 264) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      robert: file(relativePath: { eq: "ksv-robert.jpg" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 264) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      john: file(relativePath: { eq: "ksv-john.jpg" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 264) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      ashley: file(relativePath: { eq: "ksv-ashley.jpg" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 264) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      andrew: file(relativePath: { eq: "ksv-andrew.jpg" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 264) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      isaac: file(relativePath: { eq: "ksv-isaac.jpg" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 264) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  const members = [
    {
      name: "Dan Gay",
      position: "Co-Founder and Managing Partner",
      image: images.dan.childImageSharp.fluid,
      bio: [
        "Dan is a Managing Partner at KSV. Prior to co-founding KSV, he was a Managing Partner and co-led the investment strategy for the private investment arm of Kensington Capital Holdings (KCH), a single-family office based in Boston, MA. Prior to joining KCH, Dan was an innovation consultant at Innosight and Innosight Ventures, the leading authority on disruptive innovation. At Innosight, he specialized in innovation strategy and incubation within the healthcare, media and technology industries of Fortune 500 companies.",
        "Dan currently has board roles with Builder.ai, The Perfumery, Scent Beauty and Atida. He also sits on the College Advisory Board of Babson College. Dan received an MBA from the F. W. Olin Graduate School of Business at Babson College and a B.S. from the John M. Huntsman School of Business at Utah State University.",
      ],
    },
    {
      name: "Jon Gay",
      position: "Co-Founder and Managing Partner",
      image: images.jon.childImageSharp.fluid,
      bio: [
        "Jon Gay is a Managing Partner at KSV. Prior to co-founding KSV, he worked as a Vice President at HGGC in Palo Alto, CA. While at HGGC, Mr. Gay performed functions in all aspects of HGGC’s private equity practice including investment sourcing, investment analysis, and deal execution. Prior to joining HGGC, he served as an Operations Analyst at Sorenson Capital Partners’ Performance Group where he specialized in working with the senior management/staff of Sorenson’s portfolio companies.",
        "He earned his B.A. in Economics from the John M. Huntsman School of Business at Utah State University and his MBA from the Fuqua School of Business at Duke University. Jon enjoys an active lifestyle of adventure motorcycling, alpine skiing and traveling.",
      ],
    },
    {
      name: "Spencer Nam",
      position: "Co-Founder and Managing Partner",
      image: images.spencer.childImageSharp.fluid,
      bio: [
        "Spencer is a Managing Partner and co-founder of KSV Global. Since opening SV Investment’s US office in early 2017, Spencer has led SV Investment’s US activities and formation of KSV Global. Spencer brings more than 20 years of financial and strategic advisory experiences primarily in the U.S. health care industry.",
        "Prior to joining SVUS, Spencer worked as a senior research fellow at the Christensen Institute for Disruptive Innovation where he researched disruptive innovation models in the health care industry. Prior to his tenure at the Christensen Institute, Spencer spent 10 years as a licensed securities analyst for several Wall Street investment banks where he had research coverage on publicly traded companies in medical devices, diagnostics and life science tools companies. Prior to his tenure on Wall Street, Spencer was an associate at TDI Capital where he conducted investment analysis on companies in life sciences and technology sectors. Prior to TDI, Spencer was a consultant at Bain & Company where he advised senior management teams of Fortune 500 companies.",
        "Spencer holds B.A. in Mathematics from Harvard College and an MBA from Harvard Business School.",
      ],
    },
    {
      name: "Robert Gay",
      position: "Executive Advisor and Investment Committee Member",
      image: images.robert.childImageSharp.fluid,
      bio: [
        "Bob has been involved in private equity since 1986. From 2007 to 2012, he co-founded HGGC where he served as CEO until his departure and remains as a Executive Director of the firm today. From 1989 to 2004, he served as a Managing Director of Bain Capital. He was one of the firm’s senior partners and served as a member of its Management and Business Policy committees. He also led many of the firm’s investments and oversaw Bain Capital Europe, where he opened the firm’s London and Munich offices.",
        "Bob resigned as a full-time partner from HGGC and Bain Capital when The Church of Jesus Christ of Latter-day Saints asked him to take multiple positions in leadership roles. Notably, he took a volunteer assignment as a Mission President for the countries of Ghana, Sierra Leone and Liberia from July 2004 to June 2007 and full-time senior leadership position as a General Authority from July 2012 to July 2022 based in Salt Lake City, UT.",
        "Bob has been actively involved in humanitarian work all his business life. In 2001, he co-founded Unitus, a global micro-credit company, which is currently helping lift 9 million people across the world out of poverty. Unitus has been recognized worldwide and has received the prestigious Fast Company/Monitor Group Social Capitalist award naming it one of the top 45 social capital companies in the USA. Bob has also funded KOMART, a micro-credit organization serving West Africa.",
        "Bob started in private equity when he joined GE Capital from McKinsey & Co., where he was an Engagement Manager. At GE Capital, he was Executive Vice President of the GECC Capital Markets group. He also organized Sorenson Capital, a middle-market private equity firm, where he was a member of its Advisory Board.",
        "He earned a Bachelor of Arts degree in economics with an emphasis in statistics from the University of Utah and a PhD in business economics from Harvard University.",
      ],
    },
    {
      name: "Andrew Breger",
      position: "Principal",
      image: images.andrew.childImageSharp.fluid,
      bio: [
        "Andrew is a Principal at KSV. He currently serves as a Director of The Perfumery and a Board Observer of Scent Beauty. Prior to joining KSV in 2019, Andrew worked as a private equity investor at Brentwood Associates and HGGC. He began his career as an Investment Banking Analyst at Barclays focused on the technology industry.",
        "Andrew received a bachelor’s degree from Boston College, summa cum laude, and an MBA from The University of Chicago Booth School of Business with Honors.",
      ],
    },
    {
      name: "Isaac Blake",
      position: "Associate",
      image: images.isaac.childImageSharp.fluid,
      bio: [
        "Isaac is an Associate at KSV Global. Prior to joining KSV Global, he was an Associate at Oliver Wyman where he led workstreams in private equity due diligence, post-acquisition value creation, growth strategy, and operations. His casework covered the industrials, financial services, education, aerospace, and transportation sectors. While at Oliver Wyman, Isaac co-led the Core Consulting Group in the Dallas Office, helping to mentor and train new consultants.",
        "Isaac earned a B.S. in Finance from Brigham Young University. Outside of work, he enjoys watching sports, finding new restaurants, and exploring the outdoors with his wife and son.",
      ],
    },
    {
      name: "John A. Ritacco, Jr., CPA",
      position: "CFO",
      image: images.john.childImageSharp.fluid,
      bio: [
        "John is the Chief Financial Officer of KSV Global. Prior to joining KSV Global, John served as the Chief Financial Officer and Secretary of Natural Order Acquisition Corp., a publicly traded, special purpose acquisition corporation. Past experiences include acting as the Chief Financial Officer for investment management clients of Ritacco Consulting LLC; the Chief Operating Officer and Chief Financial Officer for Frontlight Capital LP, a global macro hedge fund manager that he helped launch; the Chief Financial Officer for Clough Capital Partners, L.P., a multi-billion-dollar global equity-focused investment management firm. From 2001 to 2011, John was a Vice President and Associate at Wellington Management, where he provided operational support for their hedge fund platform and prior to that was a Vice President and Director of Fund Accounting and Administration at PFPC (currently part of BNY Mellon). John began his career as an auditor in the financial services practice of PricewaterhouseCoopers LLP, where he provided audit and attestation services for investment management clients from 1992 - 1998. He received his BS in Accounting and Economics from Babson College, where he graduated in 1992. John is a Certified Public Accountant, and a member of both the American Institute of CPAs and the Massachusetts Society of CPAs.",
      ],
    },
    {
      name: "Ashley Wilburn",
      position: "Executive Assistant",
      image: images.ashley.childImageSharp.fluid,
      bio: [
        "Ashley is the Executive Assistant for the team and has over 15 years of experience working with C-level executives in Boston. She has extensive experience with all administrative tasks, including scheduling, booking travel and correspondence. Prior to working for KSV Global, Ashley was the Executive assistant to the Private Equity Investment team at Liberty Mutual. Ashley graduated from Keene State with a degree in Business Management. Ashley currently has her Real Estate license and is a Notary Public. In her free time, Ashley enjoys volunteering at her children’s schools and coaching youth sports.",
      ],
    },
  ];
  const onNext = () => {
    const index = members.findIndex(
      (member) => member.name === activeMember?.name
    );
    setActiveMember(
      index === members.length - 1 ? members[0] : members[index + 1]
    );
  };
  const onPrev = () => {
    const index = members.findIndex(
      (member) => member.name === activeMember?.name
    );
    setActiveMember(
      index === 0 ? members[members.length - 1] : members[index - 1]
    );
  };
  return (
    <Container>
      <ContainerInner>
        <Header>
          <AnimFade direction="up">
            <Eyelash>The team</Eyelash>
            <Heading>
              Our team is proven,
              <br />
              <span>dynamic, and global.</span>
            </Heading>
          </AnimFade>
          <AnimFade direction="up">
            <Copy>
              With over 25 years of experience, we take a family office approach
              to scaling and transforming companies. We work collaboratively
              with management teams who value integrity and teamwork toward
              mutual success that can be shared—and celebrated—together.
            </Copy>
          </AnimFade>
        </Header>
        <Members>
          {members.map((member, i) => (
            <Member
              key={`team-member-${i}`}
              onClick={() => setActiveMember(member)}
            >
              <AnimFade direction="right">
                <MemberImg fluid={member.image} />
              </AnimFade>
              <AnimFade direction="up">
                <MemberData>
                  <MemberName>{member.name}</MemberName>
                  <MemberPosition>{member.position}</MemberPosition>
                </MemberData>
              </AnimFade>
            </Member>
          ))}
        </Members>
      </ContainerInner>
      {activeMember && (
        <ActiveMember
          member={activeMember}
          onClose={() => setActiveMember(null)}
          onNext={onNext}
          onPrev={onPrev}
        />
      )}
      <SectionDots />
    </Container>
  );
};
