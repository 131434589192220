import React from "react";
import {
  Container,
  Content,
  Eyelash,
  Heading,
  Copy,
  ContainerInner,
  StyledImg,
  ImgContainer,
  Content1,
  Content2,
  Content3,
  Image1,
  Image2,
  Image3
} from "./UI/OurApproachUI";
import { useStaticQuery, graphql } from "gatsby";
import { AnimFade } from "./AnimFade";
import { SectionDots } from "./SectionDots";

export const OurApproach = () => {
  const images = useStaticQuery(graphql`
    query {
      approach1: file(relativePath: { eq: "ksv-our-approach-1.jpg" }) {
        childImageSharp {
          fluid(quality: 85, maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      approach2: file(relativePath: { eq: "ksv-our-approach-2.jpg" }) {
        childImageSharp {
          fluid(quality: 85, maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      approach3: file(relativePath: { eq: "ksv-our-approach-3.jpg" }) {
        childImageSharp {
          fluid(quality: 85, maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  return (
    <Container>
      <ContainerInner>
        <Content1>
          <Content>
            <AnimFade direction="up">
              <Eyelash>Our Approach</Eyelash>
              <Heading>
                We believe a good deal
                <br />
                <span>is a good deal.</span>
              </Heading>
            </AnimFade>
            <AnimFade direction="up">
              <Copy>
                We evaluate opportunities regardless of sector, geography or
                capital needs and focus on how our experience, networks and
                relationships combined together can achieve mutual success.
                While we tend to gravitate to consumer, healthcare and
                technology opportunities we maintain an opportunistic approach
                and are generalists at our core.
              </Copy>
            </AnimFade>
          </Content>
        </Content1>
        <Image1>
          <ImgContainer>
            <AnimFade direction="left">
              <StyledImg fluid={images.approach1.childImageSharp.fluid} />
            </AnimFade>
          </ImgContainer>
        </Image1>
        <Image2>
          <ImgContainer>
            <AnimFade direction="right">
              <StyledImg fluid={images.approach2.childImageSharp.fluid} />
            </AnimFade>
          </ImgContainer>
        </Image2>
        <Content2>
          <Content>
            <AnimFade direction="up">
              <Heading>
                We believe in
                <br />
                <span>strong business models.</span>
              </Heading>
            </AnimFade>
            <AnimFade direction="up">
              <Copy>
                We favor sustainable business models focused on fundamentals
                that are driving towards profitability or are already
                profitable. We invest as early as the Series B all the way up
                and into select buyout opportunities.
              </Copy>
            </AnimFade>
          </Content>
        </Content2>
        <Content3>
          <Content>
            <AnimFade direction="up">
              <Heading>
                We believe in
                <br />
                <span>robust growth.</span>
              </Heading>
            </AnimFade>
            <AnimFade direction="up">
              <Copy>
                We seek fast growing businesses that are disrupting existing
                markets or creating new markets in the US, Europe or Asia. As
                long term partners we work hand in hand with management to
                develop opportunities to accelerate growth on a global scale.
              </Copy>
            </AnimFade>
          </Content>
        </Content3>
        <Image3>
          <ImgContainer>
            <AnimFade direction="left">
              <StyledImg fluid={images.approach3.childImageSharp.fluid} />
            </AnimFade>
          </ImgContainer>
        </Image3>
      </ContainerInner>
      <SectionDots />
    </Container>
  );
};
