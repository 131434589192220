import { useCallback, useEffect, useLayoutEffect, useState } from "react";

const mqUtil = (mediaQuery: string) =>
  mediaQuery.includes("@media")
    ? mediaQuery.replace("@media", "").trim()
    : mediaQuery;

export const useMediaQuery = (mediaQuery: string) => {
  const mq = mqUtil(mediaQuery);
  const [matches, setMatches] = useState(false);

  const setInitMatches = useCallback(() => {
    setMatches(window.matchMedia(mq).matches);
  }, []);

  useEffect(setInitMatches);

  useLayoutEffect(() => {
    if (!window) return;

    const mediaQueryList = window.matchMedia(mq);
    const listener = (event: MediaQueryListEvent) => setMatches(event.matches);
    mediaQueryList.addListener(listener);
    return () => mediaQueryList.removeListener(listener);
  }, [mq]);

  return matches;
};
