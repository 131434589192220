import styled from "styled-components";
import { colors } from "../../styles/colors";
import { eyelash } from "../../styles/ui";
import { mqs } from "../../styles/mqs";
import { animated } from "react-spring";

export const Container = styled.section`
  padding-top: 69px;
  background-color: ${colors.biscay};
  color: ${colors.white};
  position: relative;
`;

export const ContainerInner = styled.div`
  margin: 0 auto;
  padding: 40px 24px 96px;
  max-width: 1168px;
  width: 100%;
  ${mqs.md} {
    padding: 40px 24px 64px;
  }
`;

export const ClocksAnim = styled(animated.div)`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 32px;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  font-size: 10px;
  .react-clock__face {
    border: 2px solid ${colors.sandrift};
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 7px;
      height: 7px;
      transform: translate(-50%, -50%);
      background-color: ${colors.sandrift};
      border-radius: 50%;
      z-index: 1;
    }
  }
  .react-clock__mark__body {
    background-color: ${colors.sandrift};
  }
  .react-clock__hand__body {
    background-color: ${colors.sandrift};
  }
  .react-clock__second-hand__body {
    background-color: ${colors.white};
  }
`;

export const Content = styled.div`
  margin-top: 40px;
  ${mqs.md} {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1.4fr 1.1fr;
  }
`;

export const Left = styled.div``;

export const Right = styled.div``;

export const Eyelash = styled.span`
  ${eyelash};
  margin-bottom: 24px;
`;

export const Heading = styled.h1`
  font-size: 32px;
  margin-bottom: 36px;
  ${mqs.md} {
    font-size: 40px;
    margin-bottom: 0;
  }
  ${mqs.custom(960)} {
    font-size: 48px;
  }
  ${mqs.custom(1140)} {
    font-size: 59px;
  }
  color: ${colors.white};
  span {
    color: ${colors.curiousBlue};
  }
`;

export const StyledImg = styled.div`
  margin-left: auto;
  width: 75%;
  overflow: hidden;
  ${mqs.md} {
    width: 100%;
  }
`;

export const ImgAnim = styled(animated.div)``;

export const HeadingAnim = styled(animated.div)``;

export const EyelashAnim = styled(animated.div)``;
