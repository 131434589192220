import { colors } from "../../styles/colors";
import styled, { css } from "styled-components";
import * as buttons from "../../styles/buttons";
import { mqs } from "../../styles/mqs";

export const Container = styled.footer`
  background-color: ${colors.floralWhite};
  padding: 48px 0 28px;
  color: ${colors.white};
`;

export const ContainerInner = styled.div`
  margin: 0 auto;
  padding: 0 24px;
  max-width: 1168px;
  width: 100%;
`;

export const Upper = styled.div`
  display: grid;
  ${mqs.md} {
    grid-template-columns: 1fr 1fr;
  }
  svg {
    path {
      fill: ${colors.biscay};
    }
  }
`;

export const Lower = styled.div`
  color: ${colors.silver};
  font-size: 18px;
`;

export const Nav = styled.nav`
  display: grid;
  grid-auto-flow: column;
  margin: 36px 0 92px;
  ${mqs.md} {
    grid-gap: 80px;
    justify-self: center;
    justify-content: center;
    margin: 0;
  }
`;

const navItemStyles = css`
  ${buttons.noStyle};
  font-size: 18px;
  color: ${colors.biscay};
  display: block;
  margin-bottom: 8px;
  text-decoration: none;
`;

export const NavItem = styled.button`
  ${navItemStyles};
`;

export const NavLogin = styled.a`
  ${navItemStyles};
`;
