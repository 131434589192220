import React from "react";
import { colors } from "../../styles/colors";

export const Hamburger = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="37"
    height="32"
    viewBox="0 0 37 32"
  >
    <g fill="none">
      <g strokeWidth="2" stroke={colors.sandrift}>
        <path d="M0 30.4L37 30.4" />
      </g>
      <g strokeWidth="2" stroke={colors.sandrift}>
        <path d="M0 15.9L37 15.9" />
      </g>
      <g strokeWidth="2" stroke={colors.sandrift}>
        <path d="M0 1.4L37 1.4" />
      </g>
    </g>
  </svg>
);
