import React from "react";
import { colors } from "../../styles/colors";

export const Close = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="37"
    height="37"
    viewBox="0 0 37 37"
  >
    <g fill="none">
      <path
        strokeWidth="2"
        stroke={colors.sandrift}
        d="M1.2 1.2L35.8 35.8M35.8 1.1L1.2 35.8"
      />
    </g>
  </svg>
);
