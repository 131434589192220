import { css } from "styled-components";
import { colors } from "./colors";
import { mqs } from "./mqs";
import { fonts } from "./fonts";

export const eyelash = css`
  font-family: ${fonts.montserrat}, serif;
  font-weight: bold;
  display: block;
  font-size: 14px;
  color: ${colors.sandrift};
  text-transform: uppercase;
  letter-spacing: 0.1em;
`;

export const heading = css`
  color: ${colors.white};
  span {
    color: ${colors.sandrift};
  }
  font-size: 32px;
  ${mqs.md} {
    font-size: 30px;
  }
  ${mqs.lg} {
    font-size: 40px;
  }
  ${mqs.custom(1140)} {
    font-size: 48px;
  }
`;

export const copy = css`
  color: ${colors.spaceShuttle};
  line-height: 1.6;
  font-size: 20px;
  ${mqs.md} {
    font-size: 22px;
  }
  ${mqs.lg} {
    font-size: 24px;
  }
`;
