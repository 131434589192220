import React from "react";
import {
  Container,
  ContainerInner,
  Upper,
  Lower,
  Nav,
  NavItem,
  NavLogin,
} from "./UI/FooterUI";
import { Logo } from "./svgs";
import { Section } from "../typings/sections";

type Props = {
  sections: Section[];
};

export const Footer = ({ sections }: Props) => {
  const navSections = sections.filter((section) => section.name !== "Contact");
  const contactSection = sections.find((section) => section.name === "Contact");
  const onScroll = (top: number) => {
    window.scroll({ top, behavior: "smooth" });
  };
  const onClick = (ref?: React.RefObject<HTMLDivElement>) => {
    const offset = ref?.current?.getBoundingClientRect().top || 0;
    const top = window.scrollY + offset;
    onScroll(top);
  };
  return (
    <Container>
      <ContainerInner>
        <Upper>
          <Logo />
          <Nav>
            <div>
              {navSections.map(({ name, ref }, i) => (
                <NavItem key={`nav-item-${i}`} onClick={() => onClick(ref)}>
                  {name}
                </NavItem>
              ))}
            </div>
            <div>
              <NavItem onClick={() => onClick(contactSection?.ref)}>
                {contactSection?.name}
              </NavItem>
              <NavLogin href="http://google.com" target="blank">
                Log In
              </NavLogin>
            </div>
          </Nav>
        </Upper>
        <Lower>
          © {new Date().getFullYear()} by GIP Investment Adviser, LLC (dba KSV
          Global)
        </Lower>
      </ContainerInner>
    </Container>
  );
};
