import React from "react";
import { AnimFade } from "./AnimFade";
import * as Svg from "./svgs";
import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50px;
  display: flex;
  justify-content: center;
  pointer-events: none;
`;

export const SectionDots = () => {
  return (
    <Container>
      <AnimFade direction="up">
        <Svg.Dots />
      </AnimFade>
    </Container>
  );
};
