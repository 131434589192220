import styled from "styled-components";
import { colors } from "../../styles/colors";
import * as buttons from "../../styles/buttons";
import { mqs } from "../../styles/mqs";
import { animated } from "react-spring";

type IsOpen = { isOpen: boolean };

export const Container = styled.header`
  background-color: ${colors.biscay};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 69px;
  padding: 0 26px;
  z-index: 2;
  border-bottom: 2px solid ${colors.rhino};
  display: grid;
  align-items: center;
`;

export const ContainerInner = styled(animated.div)`
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  ${mqs.lg} {
    grid-template-columns: 1fr 6fr 1fr;
  }
`;

export const Logo = styled.button`
  ${buttons.noStyle};
  margin: 0;
`;

export const Nav = styled.nav`
  justify-self: center;
`;

export const NavItem = styled.button`
  ${buttons.noStyle};
  color: ${colors.white};
  padding: 0 24px;
  transition: color 0.2s ease;
  position: relative;
  ${mqs.lg} {
    &:hover {
      &:after {
        width: calc(100% - 48px);
      }
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -4px;
      left: 24px;
      width: 0;
      height: 2px;
      background-color: ${colors.curiousBlue};
      transition: width 0.3s ease;
    }
  }
`;

export const CtaButtons = styled.nav`
  justify-self: flex-end;
  display: flex;
  align-items: center;
`;

export const CtaContact = styled.button`
  ${buttons.primary};
`;

export const CtaLogin = styled.a`
  ${buttons.secondary};
  margin-left: 15px;
`;

export const Hamburger = styled.button`
  justify-self: flex-end;
  ${buttons.noStyle};
`;

export const Close = styled.button`
  ${buttons.noStyle};
  position: absolute;
  top: 24px;
  right: 25px;
`;

export const MobileMenu = styled.div<IsOpen>`
  transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : "translateX(100%)")};
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(100% - 69px);
  background-color: ${colors.biscay};
  color: ${colors.white};
  padding: 48px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: transform 0.25s ease;
  button {
    padding: 0;
    font-size: 20px;
    margin-bottom: 32px;
  }
  & > * {
    &:nth-last-child(2),
    &:nth-last-child(3) {
      width: 100%;
      margin: 0 0 20px;
      height: 46px;
    }
  }
`;
