import React, { useEffect } from "react";
import { AnimFade } from "./AnimFade";
import {
  Close,
  Container,
  ContainerInner,
  MemberCopy,
  MemberBio,
  PrevArrow,
  NextArrow,
  ArrowsContainer,
  Arrows,
  ActiveName,
  ActiveData,
  ActiveImg
} from "./UI/ActiveMemberUI";
import * as Svg from "./svgs";
import { MemberPosition } from "./UI/TheTeamUI";
import { TeamMember } from "../typings/sections";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { mqUtil, mqs } from "../styles/mqs";

type Props = {
  member: TeamMember;
  onClose: () => void;
  onNext: () => void;
  onPrev: () => void;
};

export const ActiveMember = ({ member, onClose, onNext, onPrev }: Props) => {
  const isTablet = useMediaQuery(mqUtil(mqs.md));
  useEffect(() => {
    const docClass = document.documentElement.classList;
    docClass.add("page-lock");
    return () => {
      docClass.remove("page-lock");
    };
  }, []);
  const handleClick = (direction: "prev" | "next") => {
    if (!isTablet) {
      const el = document.getElementById("active-member");
      if (el) el.scrollTop = 0;
    }
    if (direction === "next") {
      onNext();
    } else {
      onPrev();
    }
  };
  return (
    <Container id="active-member">
      <ContainerInner>
        <AnimFade direction="up">
          <ActiveImg fluid={member.image} />
        </AnimFade>
        <AnimFade direction="up" delay={50}>
          <ActiveData>
            <ActiveName>{member.name}</ActiveName>
            <MemberPosition>{member.position}</MemberPosition>
          </ActiveData>
        </AnimFade>
        <MemberBio>
          <AnimFade direction="up" delay={50} threshold={0.1}>
            {member.bio.map((paragraph, i) => (
              <MemberCopy key={`bio-${i}`}>{paragraph}</MemberCopy>
            ))}
          </AnimFade>
        </MemberBio>
        <ArrowsContainer>
          <AnimFade direction="up" delay={100}>
            <Arrows>
              <NextArrow onClick={() => handleClick("prev")}>
                <Svg.LeftArrow />
              </NextArrow>
              <PrevArrow onClick={() => handleClick("next")}>
                <Svg.RightArrow />
              </PrevArrow>
            </Arrows>
          </AnimFade>
        </ArrowsContainer>
      </ContainerInner>
      <Close onClick={onClose}>
        <Svg.Close />
      </Close>
    </Container>
  );
};
