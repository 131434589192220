import styled from "styled-components";
import { colors } from "../../styles/colors";
import { heading, eyelash, copy } from "../../styles/ui";
import { mqs } from "../../styles/mqs";

export const Container = styled.div`
  background-color: ${colors.vistaWhite};
  padding: 90px 0;
  color: ${colors.white};
  position: relative;
`;

export const ContainerInner = styled.div`
  margin: 0 auto;
  max-width: 1120px;
  width: 100%;
  display: grid;
  grid-template-areas:
    "content"
    "image";
  ${mqs.md} {
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "image content";
  }
`;

export const Content = styled.div`
  grid-area: content;
  padding: 0 24px;
`;

export const Heading = styled.h2`
  margin-bottom: 24px;
  ${heading};
`;

export const Eyelash = styled.span`
  margin-bottom: 18px;
  ${eyelash};
`;

export const Copy = styled.p`
  margin-bottom: 40px;
  ${copy};
`;

export const ImgContainer = styled.div`
  grid-area: image;
  ${mqs.md} {
    padding-right: 50px;
  }
`;
