import React from "react";
import { CurrentInvestments } from "./CurrentInvestments";
import { PastInvestments } from "./PastInvestments";

export const Investments = () => {
  return (
    <div id="investments">
      <CurrentInvestments />
      <PastInvestments />
    </div>
  );
};
