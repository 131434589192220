import React from "react";
import {
  Content,
  Container,
  Eyelash,
  Heading,
  Copy,
  ContainerInner,
  ImgContainer,
} from "./UI/HistoryUI";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { AnimFade } from "./AnimFade";
import { SectionDots } from "./SectionDots";

export const History = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "ksv-history.png" }) {
        childImageSharp {
          fluid(quality: 85, maxWidth: 990) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  return (
    <Container>
      <ContainerInner>
        <ImgContainer>
          <AnimFade direction="right">
            <Img fluid={data.file.childImageSharp.fluid} />
          </AnimFade>
        </ImgContainer>
        <Content>
          <AnimFade direction="up">
            <Eyelash>History</Eyelash>
            <Heading>
              Founded on a
              <br />
              <span>global partnership</span>
            </Heading>
          </AnimFade>
          <AnimFade direction="up">
            <Copy>
              In 2018, Kensington Capital Holdings, a leading US family office,
              partnered with SV Investment, a leading Korean venture capital
              firm, to create KSV Global. A distinctive and differentiated
              global growth fund offering cross-border opportunities to
              investors and entrepreneurs. By leveraging the extensive local and
              global networks through the formation of KSV Global, international
              investors and entrepreneurs will benefit from reaching the global
              arena with a trusted partner capable of navigating the evolving
              landscape of innovation across the US, Europe and Asia.
            </Copy>
          </AnimFade>
        </Content>
      </ContainerInner>
      <SectionDots />
    </Container>
  );
};
