import React from "react";
import styled from "styled-components";
import { colors } from "../styles/colors";
import { useScrollPosition } from "../hooks/useScrollPosition";
import * as Svg from "./svgs";

type Animate = { animate: boolean };

const Container = styled.div<Animate>`
  position: absolute;
  left: 0;
  right: 0;
  top: 92%;
  display: grid;
  justify-content: center;
  pointer-events: none;
  z-index: 1;
  opacity: ${({ animate }) => (animate ? 1 : 0)};
  transition: opacity 0.3s ease;
`;

const Square = styled.span`
  display: block;
  width: 5px;
  height: 5px;
  background-color: ${colors.sandrift};
  transform: rotate(45deg);
  justify-self: center;
`;

const Line = styled.span<Animate>`
  display: block;
  width: 1px;
  margin: 8px 0 4px;
  background-color: ${colors.sandrift};
  transition: height 0.75s ease;
  justify-self: center;
  height: ${({ animate }) => (animate ? "86px" : "24px")};
`;

export const ScrollIndicator = () => {
  const scroll = useScrollPosition();
  const animate = scroll.y > 1;
  return (
    <Container animate={animate}>
      <Square />
      <Line animate={animate} />
      <Svg.DownArrow />
    </Container>
  );
};
