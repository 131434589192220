export const colors = {
  white: "#ffffff",
  black: "#000000",
  biscay: "#323E54",
  curiousBlue: "#3E92CC",
  rhino: "#3B4759",
  sandrift: "#AD9378",
  vistaWhite: "#E2DED7",
  blackPearl: "#01161E",
  wildSand: "#EAE8E4",
  spaceShuttle: "#4D453E",
  floralWhite: "#F9F6F2",
  silver: "#C4C4C4",
  lochmara: "#3A79A8"
};
