import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { GlobalStyle } from "../components/GlobalStyle";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { WhoWeAre } from "../components/WhoWeAre";
import { TheTeam } from "../components/TheTeam";
import { Hero } from "../components/Hero";
import { OurApproach } from "../components/OurApproach";
import { Investments } from "../components/Investments";
import { History } from "../components/History";
import { Contact } from "../components/Contact";
import { SEO } from "../components/SEO";
// polyfills
import smoothscroll from "smoothscroll-polyfill";
import "intersection-observer";

const Section = styled.section`
  overflow-x: hidden;
`;

export default () => {
  const sections = [
    {
      name: "Who We Are",
      ref: useRef<HTMLDivElement>(null),
      Component: WhoWeAre,
    },
    {
      name: "The Team",
      ref: useRef<HTMLDivElement>(null),
      Component: TheTeam,
    },
    {
      name: "Our Approach",
      ref: useRef<HTMLDivElement>(null),
      Component: OurApproach,
    },
    {
      name: "Investments",
      ref: useRef<HTMLDivElement>(null),
      Component: Investments,
    },
    {
      name: "History",
      ref: useRef<HTMLDivElement>(null),
      Component: History,
    },
    {
      name: "Contact",
      ref: useRef<HTMLDivElement>(null),
      Component: Contact,
    },
  ];
  useEffect(() => {
    smoothscroll.polyfill();
  }, []);
  return (
    <>
      <GlobalStyle />
      <SEO title="KSV GLOBAL | HOME" />
      <Header sections={sections} />
      <main>
        <Hero />
        {sections.map(({ Component, ref, name }) => (
          <Section key={`section-${name}`} ref={ref}>
            <Component />
          </Section>
        ))}
      </main>
      <Footer sections={sections} />
    </>
  );
};
