import styled from "styled-components";
import { colors } from "../../styles/colors";
import { heading, eyelash, copy } from "../../styles/ui";
import Img from "gatsby-image";
import { mqs } from "../../styles/mqs";
import * as buttons from "../../styles/buttons";

export const Container = styled.div`
  background-color: ${colors.wildSand};
  padding: 100px 0 165px;
  position: relative;
`;

export const ContainerInner = styled.div`
  margin: 0 auto;
  padding: 0 24px;
  max-width: 1168px;
  width: 100%;
`;

export const Header = styled.div`
  ${mqs.md} {
    width: 50%;
  }
`;

export const Heading = styled.h2`
  margin-bottom: 24px;
  ${heading};
`;

export const Eyelash = styled.span`
  margin-bottom: 18px;
  ${eyelash};
`;

export const Copy = styled.p`
  margin-bottom: 40px;
  ${copy};
`;

export const Members = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: flex-start;
  ${mqs.sm} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mqs.lg} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const MemberImg = styled(Img)`
  transition: transform 0.3s ease;
  width: 132px;
  height: 132px;
  border-radius: 50%;
  transform: translateX(0);
`;

export const Member = styled.button`
  ${buttons.noStyle};
  white-space: unset;
  text-align: left;
  margin-bottom: 48px;
  &:last-child {
    margin-bottom: 0;
  }
  ${mqs.md} {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      margin-bottom: 64px;
    }
  }
  ${mqs.lg} {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      margin-bottom: 78px;
    }
    &:nth-child(4),
    &:nth-child(5) {
      margin-bottom: 0;
    }
  }
  &:hover,
  &:focus {
    ${MemberImg} {
      transform: translateX(16px);
    }
  }
`;

export const MemberData = styled.div`
  padding: 24px 0 0 66px;
  ${mqs.md} {
    padding: 36px 0 0 66px;
  }
`;

export const MemberName = styled.h3`
  font-size: 32px;
  line-height: 1.6;
  color: ${colors.black};
  &:before {
    content: "";
    display: block;
    height: 2px;
    width: 63px;
    margin-bottom: 10px;
    background-color: ${colors.sandrift};
  }
`;

export const MemberPosition = styled.span`
  ${eyelash};
`;
