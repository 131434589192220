import styled from "styled-components";
import { colors } from "../../styles/colors";
import { heading } from "../../styles/ui";
import Img from "gatsby-image";
import { mqs } from "../../styles/mqs";
import { fonts } from "../../styles/fonts";

type Width = { width?: number };

export const Container = styled.div`
  background-color: ${colors.wildSand};
  padding: 90px 0 162px;
  color: ${colors.white};
  position: relative;
`;

export const ContainerInner = styled.div`
  margin: 0 auto;
  padding: 0 24px;
  max-width: 1168px;
  width: 100%;
`;

export const Heading = styled.h2`
  margin-bottom: 60px;
  ${heading};
`;

export const Companies = styled.div`
  display: grid;
  grid-gap: 80px 10px;
  grid-template-columns: repeat(2, 1fr);
  ${mqs.md} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${mqs.lg} {
    grid-gap: 80px 20px;
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const Company = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledImg = styled(Img)<Width>`
  width: ${({ width }) => `${width}px !important`};
  margin: auto;
`;

export const Badge = styled.span`
  font-family: ${fonts.montserrat}, serif;
  font-weight: bold;
  position: absolute;
  bottom: -40px;
  left: 50%;
  padding: 0 11px;
  transform: translateX(-50%);
  background-color: ${colors.sandrift};
  border-radius: 999px;
  white-space: nowrap;
  height: 20px;
  color: ${colors.white};
  font-size: 9px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  ${mqs.md} {
    height: 30px;
    font-size: 11px;
  }
`;
