import styled from "styled-components";
import { colors } from "../../styles/colors";
import { eyelash, copy, heading } from "../../styles/ui";
import Img from "gatsby-image";
import { mqs } from "../../styles/mqs";
import { animated } from "react-spring";

export const Container = styled.div`
  background-color: ${colors.vistaWhite};
  padding: 100px 0 92px;
  position: relative;
  ${mqs.md} {
    padding: 100px 0 64px;
  }
`;

export const ContainerInner = styled.div`
  width: 100%;
  max-width: 1410px;
  margin: 0 auto;
  ${mqs.md} {
    padding-right: 24px;
  }
  ${mqs.custom(1260)} {
    padding-right: 145px;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-areas:
    "content"
    "image";
  ${mqs.md} {
    grid-auto-flow: column;
    grid-template-columns: 1.4fr 1.1fr;
    grid-gap: 24px;
    grid-template-areas: "image content";
  }
  ${mqs.lg} {
    grid-gap: 88px;
    grid-template-columns: 630px auto;
  }
`;

export const ContentInner = styled.div`
  grid-area: content;
  padding: 0 24px;
  margin-bottom: 40px;
  ${mqs.md} {
    padding-left: 0;
    margin-bottom: 0;
  }
`;

export const Intro = styled(animated.div)`
  ${mqs.lg} {
    margin-left: -280px;
  }
`;

export const ImgContainer = styled(animated.div)`
  grid-area: image;
`;

export const StyledImg = styled(Img)`
  width: 100%;
  max-width: 630px;
  align-self: flex-start;
`;

export const Heading = styled.h2`
  ${heading};
`;

export const Eyelash = styled.span`
  margin-bottom: 18px;
  ${eyelash};
`;

export const Copy = styled(animated.p)`
  ${copy};
  margin-top: 40px;
  ${mqs.lg} {
    margin-top: 60px;
  }
`;
