import React from "react";

export const Logo = () => (
  <svg
    width="128"
    height="38"
    viewBox="0 0 128 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1364_1394)">
      <path
        d="M56.2237 14.5627V16.8297H50.8558V14.5627H51.9428L49.2692 11.5802L47.0283 13.4032V14.5627H48.414V16.8297H42.5361V14.5627H44.1795V6.85826H42.5361V4.59131H48.414V6.85826H47.0283V10.8385L51.9274 6.85826H50.588V4.59131H55.9301V6.85826H55.0853L51.2628 9.99228L55.5437 14.5993L56.2237 14.5627Z"
        fill="white"
      />
      <path
        d="M68.7162 11.0526C69.1206 11.3313 69.4498 11.7081 69.6738 12.1487C69.8978 12.5894 70.0096 13.0798 69.9989 13.5755C70.0166 14.2433 69.8255 14.8997 69.4529 15.4507C69.0767 15.986 68.5587 16.4025 67.9589 16.652C67.2778 16.9427 66.5445 17.0868 65.8056 17.0751C65.0688 17.0821 64.3381 16.9382 63.6574 16.652C62.9179 16.326 62.2248 15.9009 61.5968 15.388V16.8505H59.1138V12.3845L60.8756 12.2957C61.5027 13.0615 62.2821 13.6845 63.1629 14.1239C63.9189 14.5542 64.7643 14.7976 65.6305 14.8343C65.9886 14.8526 66.3449 14.7713 66.6608 14.5993C66.7806 14.53 66.8792 14.4284 66.9455 14.3058C67.0119 14.1831 67.0435 14.0442 67.0368 13.9045C67.0368 13.5337 66.7483 13.1942 66.1765 12.8599C65.2131 12.4134 64.2089 12.0635 63.1783 11.8152C62.0363 11.5594 60.9744 11.0211 60.0874 10.2482C59.7703 9.93783 59.5204 9.56392 59.3536 9.15023C59.1869 8.73655 59.1069 8.29218 59.1189 7.84544C59.1077 7.18264 59.3123 6.53456 59.7011 6.00158C60.0996 5.45932 60.6329 5.03396 61.2465 4.76887C61.884 4.47592 62.5763 4.32623 63.2762 4.33009C64.6082 4.29794 65.9007 4.79032 66.8823 5.70384V4.58084H69.3653V8.92145L67.6911 9.01024C67.3272 8.28168 66.7684 7.67167 66.0786 7.24997C65.4489 6.84684 64.7215 6.62809 63.9768 6.61793C63.5034 6.59542 63.0325 6.69993 62.6117 6.9209C62.4591 6.99559 62.3302 7.11188 62.2392 7.25673C62.1482 7.40158 62.0988 7.56925 62.0965 7.74097C62.1156 7.93203 62.1864 8.11404 62.3009 8.267C62.4154 8.41997 62.5693 8.53796 62.7456 8.60803C63.502 8.97039 64.2948 9.24886 65.1102 9.43856C66.3923 9.76957 67.611 10.3151 68.7162 11.0526Z"
        fill="white"
      />
      <path
        d="M85.2011 6.85826H83.8617L79.112 17.0752H77.6129L72.8941 6.85826H71.5598V4.59131H77.0926V6.85826H75.8201L78.5504 13.2673L81.1726 6.85826H79.8898V4.59131H85.2062L85.2011 6.85826Z"
        fill="white"
      />
      <path
        d="M46.575 22.5283C45.8875 22.933 45.3254 23.5247 44.9523 24.2364C44.5346 25.0402 44.3287 25.9398 44.3547 26.8481C44.3374 27.7275 44.5394 28.597 44.9419 29.3762C45.3152 30.0864 45.8774 30.6764 46.5647 31.079C47.2675 31.4892 48.0669 31.6986 48.8777 31.6849C50.2001 31.7365 51.5 31.3273 52.5611 30.5253V28.1434H48.6871V26.7331H54.2198V31.2775C53.5467 31.8893 52.763 32.3629 51.912 32.6721C50.94 33.0308 49.912 33.2078 48.8777 33.1944C47.7166 33.2187 46.5689 32.9399 45.5447 32.3848C44.5932 31.8613 43.8074 31.0754 43.278 30.1179C42.7287 29.1099 42.4498 27.9734 42.4692 26.8219C42.451 25.6756 42.7299 24.5445 43.278 23.5417C43.7907 22.6023 44.5388 21.8167 45.4468 21.2643C46.3401 20.7221 47.3627 20.4385 48.4038 20.4442C49.2297 20.4301 50.0493 20.592 50.8095 20.9195C51.4911 21.2132 52.1051 21.6472 52.6126 22.194V20.6949H54.2198V24.4871L53.0711 24.5759C52.659 23.8072 52.075 23.147 51.3659 22.6485C50.6018 22.1421 49.7024 21.8867 48.7901 21.9172C48.0115 21.9188 47.2471 22.1297 46.575 22.5283Z"
        fill="white"
      />
      <path
        d="M68.5565 29.1882V32.9385H57.7383V31.4551H59.5156V22.1836H57.7383V20.7002H63.0907V22.1836H61.3289V31.4708H66.8616V29.1882H68.5565Z"
        fill="white"
      />
      <path
        d="M74.2542 32.3744C73.3104 31.8465 72.5294 31.0636 71.9979 30.1127C71.4423 29.1106 71.1597 27.9766 71.1788 26.8272C71.1582 25.671 71.4409 24.53 71.9979 23.5208C72.5295 22.5714 73.3106 21.7902 74.2542 21.2643C75.2328 20.7159 76.3353 20.4351 77.4533 20.4495C78.5646 20.4368 79.66 20.7177 80.6318 21.2643C81.5756 21.793 82.3552 22.578 82.8831 23.5313C83.438 24.5374 83.7206 25.6745 83.7021 26.8272C83.7213 27.9766 83.4386 29.1106 82.8831 30.1127C82.3591 31.0588 81.5894 31.841 80.6576 32.3744C79.6844 32.9171 78.5896 33.1959 77.4791 33.1841C76.3531 33.2024 75.2417 32.9234 74.2542 32.3744ZM79.7149 31.032C80.3777 30.6185 80.913 30.025 81.2603 29.3188C81.6408 28.5457 81.8316 27.691 81.8167 26.8272C81.8321 25.9567 81.6413 25.0951 81.2603 24.3148C80.913 23.6086 80.3777 23.0151 79.7149 22.6015C79.0322 22.1869 78.249 21.9735 77.4533 21.9852C76.6478 21.9723 75.8544 22.1857 75.1609 22.6015C74.495 23.0159 73.959 23.6137 73.6155 24.3252C73.2413 25.1039 73.0542 25.9612 73.0694 26.8272C73.0526 27.695 73.2398 28.5545 73.6155 29.3345C73.9614 30.0402 74.4972 30.6323 75.1609 31.0425C75.8592 31.4545 76.6558 31.6641 77.4636 31.6484C78.2562 31.6606 79.0361 31.4471 79.7149 31.032Z"
        fill="white"
      />
      <path
        d="M98.0955 29.6113C98.1168 30.093 98.0181 30.5725 97.8083 31.0052C97.5985 31.438 97.2844 31.8101 96.8952 32.0872C95.8716 32.7251 94.679 33.0279 93.4797 32.9543H86.7827V31.4708H88.56V22.1837H86.7827V20.7002H93.1706C94.2321 20.6318 95.2837 20.9423 96.143 21.5777C96.4788 21.8547 96.7467 22.2069 96.9255 22.6066C97.1042 23.0063 97.1891 23.4427 97.1733 23.8813C97.1902 24.4698 97.0205 25.0483 96.6891 25.5318C96.3481 26.005 95.8895 26.3781 95.36 26.6131C97.1837 27.0414 98.0955 28.0408 98.0955 29.6113ZM90.3682 22.1837V26.096H92.7482C93.4245 26.133 94.0961 25.9619 94.6749 25.605C94.9079 25.4341 95.0941 25.2059 95.2158 24.9417C95.3375 24.6775 95.3906 24.3862 95.3703 24.0954C95.3915 23.8183 95.343 23.5402 95.2295 23.2872C95.116 23.0342 94.9411 22.8145 94.7212 22.6485C94.2301 22.321 93.6498 22.1584 93.0624 22.1837H90.3682ZM96.2512 29.4703C96.2711 29.1684 96.2086 28.8668 96.0704 28.5987C95.9323 28.3305 95.7239 28.1061 95.4682 27.9503C94.7937 27.5861 94.0375 27.4061 93.2736 27.4279H90.3682V31.4499H93.2942C95.2724 31.4639 96.2615 30.804 96.2615 29.4703H96.2512Z"
        fill="white"
      />
      <path
        d="M114.421 31.4708V32.9542H109.65V31.4708H111.129L110.021 28.6136H104.787L103.68 31.4708H105.297V32.9542H100.563V31.4708H101.954L105.9 22.1627H104.499V20.7002H110.392V22.1627H109.089L113.055 31.4708H114.421ZM109.475 27.2921L107.466 22.1836H107.327L105.318 27.2921H109.475Z"
        fill="white"
      />
      <path
        d="M128.005 29.1882V32.9385H117.187V31.4551H118.964V22.1836H117.187V20.7002H122.539V22.1836H120.783V31.4708H126.31V29.1882H128.005Z"
        fill="white"
      />
      <path
        d="M19.9313 2.74749L19.7304 2.28261L18.7413 0L17.7677 2.24082L17.5719 2.69003L13.9556 11.0004L14.2853 11.1258L18.7413 9.45952L23.1923 11.1258L23.522 11.0004L19.9313 2.74749Z"
        fill="#AD9378"
      />
      <path
        d="M23.1923 26.8743L18.7413 28.5457L14.2853 26.8743L13.9556 26.9996L17.2577 34.5944L17.4586 35.0541L18.7413 38L20.0447 35.0018L20.2507 34.5265L23.522 26.9996L23.1923 26.8743Z"
        fill="#AD9378"
      />
      <path
        d="M34.5719 17.702L34.1186 17.4983L26.6283 14.1501L26.5046 14.4844L28.1531 19.0027L26.5046 23.5156L26.6283 23.8499L34.0516 20.5331L34.5204 20.3242L37.4774 19.0027L34.5719 17.702Z"
        fill="#AD9378"
      />
      <path
        d="M10.9728 14.4844L10.8491 14.1501L2.65304 17.8117L2.21001 18.0154L0 19.0027L2.25122 20.0055L2.7097 20.2093L10.8491 23.8499L10.9728 23.5156L9.32942 19.0027L10.9728 14.4844Z"
        fill="#AD9378"
      />
      <path
        d="M15.8305 11.7368L15.0578 11.4338L14.2851 11.1257L13.9554 11.0003L13.4454 10.7966L12.9354 10.5981L8.87598 8.99976L10.4523 13.1158L10.6481 13.6329L10.849 14.15L10.9726 14.4843L11.2766 15.2678L11.5754 16.0513L12.5748 18.6578L14.4087 14.6097L18.4012 12.7501L15.8305 11.7368Z"
        fill="white"
      />
      <path
        d="M27.0301 24.8894L26.8292 24.3671L26.6283 23.85L26.5046 23.5157L26.2059 22.7374L25.9019 21.9539L24.9077 19.3474L23.0686 23.3903L19.0813 25.255L21.6519 26.2632L22.4246 26.5713L23.1922 26.8743L23.5219 26.9996L24.0319 27.2034L24.5471 27.4071L28.6013 29.0002L27.0301 24.8894Z"
        fill="white"
      />
      <path
        d="M24.5471 10.5981L24.0319 10.7966L23.5219 11.0003L23.1922 11.1257L22.4246 11.4338L21.6519 11.7368L19.0813 12.7501L23.0686 14.6097L24.9077 18.6578L25.9019 16.0513L26.2059 15.2678L26.5046 14.4843L26.6283 14.15L26.8292 13.6329L27.0301 13.1158L28.6013 8.99976L24.5471 10.5981Z"
        fill="white"
      />
      <path
        d="M14.4087 23.3903L12.5748 19.3474L11.5754 21.9539L11.2766 22.7374L10.9726 23.5157L10.849 23.85L10.6481 24.3671L10.4523 24.8894L8.87598 29.0002L12.9354 27.4071L13.4454 27.2034L13.9554 26.9996L14.2851 26.8743L15.0578 26.5713L15.8305 26.2632L18.4012 25.255L14.4087 23.3903Z"
        fill="white"
      />
      <path
        d="M18.7412 0V9.45952L23.1921 11.1258L23.5218 11.0004L18.7412 0Z"
        fill="white"
      />
      <path
        d="M37.4774 19.0027H28.1531L26.5046 23.5157L26.6283 23.85L37.4774 19.0027Z"
        fill="white"
      />
      <path
        d="M18.7413 38V28.5457L14.2853 26.8743L13.9556 26.9996L18.7413 38Z"
        fill="white"
      />
      <path
        d="M0 19.0027H9.32942L10.9728 14.4844L10.8491 14.1501L0 19.0027Z"
        fill="white"
      />
      <path
        d="M23.0686 14.6097L24.9077 18.6578L28.6013 8.99976L23.0686 14.6097Z"
        fill="#AD9378"
      />
      <path
        d="M23.0686 23.4216L19.0813 25.2864L28.6013 29.0315L23.0686 23.4216Z"
        fill="#AD9378"
      />
      <path
        d="M14.4087 23.4216L12.5748 19.3787L8.87598 29.0314L14.4087 23.4216Z"
        fill="#AD9378"
      />
      <path
        d="M14.4087 14.6097L18.4012 12.7501L8.87598 8.99976L14.4087 14.6097Z"
        fill="#AD9378"
      />
      <path
        d="M20.6113 1.84395L20.8174 2.31404L21.3325 3.56243C24.4326 4.16138 27.275 5.7155 29.4719 8.01275C31.6907 10.3062 33.1539 13.2405 33.6601 16.4119L34.8398 16.9342L35.3086 17.1432L35.5971 17.2738L35.4992 16.5582C35.2504 14.694 34.7022 12.8836 33.8765 11.199C33.0733 9.54912 32.0179 8.03834 30.7495 6.72256C29.4845 5.40858 28.0244 4.30351 26.4222 3.4475C24.7843 2.5722 23.0164 1.97411 21.1883 1.6768L20.4619 1.55664L20.6113 1.84395Z"
        fill="white"
      />
      <path
        d="M1.55052 17.467L2.009 17.2632L3.16809 16.7409C3.39927 15.0737 3.89691 13.4559 4.64144 11.9511C5.38445 10.4413 6.36392 9.06361 7.54175 7.87164C8.71853 6.67741 10.077 5.68273 11.5651 4.92565C13.0519 4.17391 14.6489 3.6712 16.2942 3.43701L16.8093 2.25652L17.0103 1.79686L17.1442 1.4939L16.459 1.57226C14.5215 1.80078 12.6365 2.3625 10.8851 3.23329C9.17654 4.08375 7.6186 5.21509 6.27446 6.58145C4.9274 7.94477 3.81168 9.52434 2.97235 11.2564C2.10997 13.0315 1.55248 14.9428 1.32385 16.9081L1.24658 17.5975L1.55052 17.467Z"
        fill="white"
      />
      <path
        d="M35.2777 21.2173L34.7986 21.4315L33.5417 21.9903C32.9168 24.9364 31.464 27.6359 29.3586 29.7627C27.261 31.8974 24.5987 33.3705 21.6932 34.0041L21.142 35.2786L20.9307 35.7644L20.8123 36.0256L21.5644 35.8898C25.0012 35.2493 28.1639 33.5621 30.6311 31.0529C33.1057 28.5514 34.7697 25.3445 35.4014 21.8598L35.5353 21.0972L35.2777 21.2173Z"
        fill="white"
      />
      <path
        d="M16.691 35.8218L16.4849 35.3465L15.9698 34.1503C12.8413 33.6394 9.94661 32.1554 7.68611 29.9037C5.42315 27.6741 3.8909 24.793 3.297 21.6508L2.0658 21.1024L1.60216 20.8934L1.32397 20.7681L1.44247 21.5045C1.73801 23.3576 2.32777 25.1498 3.18883 26.8115C4.03318 28.4373 5.12496 29.918 6.42398 31.1991C7.72193 32.484 9.20974 33.5555 10.8337 34.3749C12.4982 35.208 14.2849 35.7619 16.1243 36.0151L16.8249 36.1143L16.691 35.8218Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1364_1394">
        <rect width="128" height="38" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
