import React from "react";
import { useSpring, animated, config } from "react-spring";
import { useInView } from "react-intersection-observer";

type Props = {
  children: React.ReactNode;
  direction: "left" | "right" | "up" | "down";
  delay?: number;
  threshold?: number;
};

const fadeMap = {
  up: "0px, 40px",
  down: "0px, -40px",
  left: "40px, 0px",
  right: "-40px, 0px"
};

export const AnimFade = ({
  children,
  direction,
  delay = 0,
  threshold = 0.4
}: Props) => {
  const [ref, inView] = useInView({ triggerOnce: true, threshold });
  const fade = useSpring({
    config: { ...config.default },
    opacity: inView ? 1 : 0,
    delay,
    transform: inView
      ? "translate(0px, 0px)"
      : `translate(${fadeMap[direction]})`,
    from: { opacity: 0, transform: `translate(${fadeMap[direction]})` }
  });
  return (
    <div ref={ref}>
      <animated.div style={fade}>{children}</animated.div>
    </div>
  );
};
