import React from "react";

export const Dots = () => (
  <svg width="57" height="20" viewBox="0 0 57 20">
    <path
      d="M2 18L2 20 0 20 0 18 2 18ZM11 18L11 20 9 20 9 18 11 18ZM20 18L20 20 18 20 18 18 20 18ZM29 18L29 20 27 20 27 18 29 18ZM38 18L38 20 36 20 36 18 38 18ZM47 18L47 20 45 20 45 18 47 18ZM57 18L57 20 55 20 55 18 57 18ZM2 9L2 11 0 11 0 9 2 9ZM11 9L11 11 9 11 9 9 11 9ZM20 9L20 11 18 11 18 9 20 9ZM29 9L29 11 27 11 27 9 29 9ZM38 9L38 11 36 11 36 9 38 9ZM47 9L47 11 45 11 45 9 47 9ZM57 9L57 11 55 11 55 9 57 9ZM2 0L2 2 0 2 0 0 2 0ZM11 0L11 2 9 2 9 0 11 0ZM20 0L20 2 18 2 18 0 20 0ZM29 0L29 2 27 2 27 0 29 0ZM38 0L38 2 36 2 36 0 38 0ZM47 0L47 2 45 2 45 0 47 0ZM57 0L57 2 55 2 55 0 57 0Z"
      fill="#AD9378"
    />
  </svg>
);
