import React, { useState } from "react";
import {
  Container,
  ContainerInner,
  Heading,
  Eyelash,
  ImgContainer,
  Companies,
  Left,
  Right,
  StyledImg,
  Center,
  ActiveHeading,
  ActiveBadge,
  ActiveDescription,
  ActiveUrl,
  MobileCompanies,
  MobileCompany,
  MobileTop,
  MobileImg,
} from "./UI/CurrentInvestmentsUI";
import { useStaticQuery, graphql } from "gatsby";
import * as Svg from "../components/svgs";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { mqUtil, mqs } from "../styles/mqs";
import { AnimFade } from "./AnimFade";

export const CurrentInvestments = () => {
  const [activeCompanyId, setActiveCompanyId] = useState("simplus");
  const images = useStaticQuery(graphql`
    query {
      simplusWhite: file(relativePath: { eq: "ksv-simplus-white.png" }) {
        childImageSharp {
          fixed(quality: 95, width: 190) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      simplusGold: file(relativePath: { eq: "ksv-simplus-gold.png" }) {
        childImageSharp {
          desktop: fixed(quality: 95, width: 190) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          mobile: fixed(quality: 95, width: 169) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      cleneWhite: file(relativePath: { eq: "ksv-clene-white.png" }) {
        childImageSharp {
          fixed(quality: 95, width: 136) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      cleneGold: file(relativePath: { eq: "ksv-clene-gold.png" }) {
        childImageSharp {
          desktop: fixed(quality: 95, width: 136) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          mobile: fixed(quality: 95, width: 121) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      atidaWhite: file(relativePath: { eq: "ksv-atida-white.png" }) {
        childImageSharp {
          fixed(quality: 95, width: 107) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      atidaGold: file(relativePath: { eq: "ksv-atida-gold.png" }) {
        childImageSharp {
          desktop: fixed(quality: 95, width: 107) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          mobile: fixed(quality: 95, width: 107) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      patternWhite: file(relativePath: { eq: "ksv-pattern-white.png" }) {
        childImageSharp {
          fixed(quality: 95, width: 153) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      patternGold: file(relativePath: { eq: "ksv-pattern-gold.png" }) {
        childImageSharp {
          desktop: fixed(quality: 95, width: 153) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          mobile: fixed(quality: 95, width: 136) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      perfumeryWhite: file(
        relativePath: { eq: "ksv-the-perfumery-white.png" }
      ) {
        childImageSharp {
          fixed(quality: 95, width: 211) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      perfumeryGold: file(relativePath: { eq: "ksv-the-perfumery-gold.png" }) {
        childImageSharp {
          desktop: fixed(quality: 95, width: 211) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          mobile: fixed(quality: 95, width: 188) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      healthHeroWhite: file(relativePath: { eq: "ksv-health-hero-white.png" }) {
        childImageSharp {
          fixed(quality: 95, width: 211) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      healthHeroGold: file(relativePath: { eq: "ksv-health-hero-gold.png" }) {
        childImageSharp {
          desktop: fixed(quality: 95, width: 211) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          mobile: fixed(quality: 95, width: 188) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      tinubuSquareWhite: file(
        relativePath: { eq: "ksv-tinubu-square-white.png" }
      ) {
        childImageSharp {
          fixed(quality: 79, width: 103) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      tinubuSquareGold: file(
        relativePath: { eq: "ksv-tinubu-square-gold.png" }
      ) {
        childImageSharp {
          desktop: fixed(quality: 79, width: 103) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          mobile: fixed(quality: 95, width: 150) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      builderWhite: file(relativePath: { eq: "ksv-builder-white.png" }) {
        childImageSharp {
          fixed(quality: 95, width: 153) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      builderGold: file(relativePath: { eq: "ksv-builder-gold.png" }) {
        childImageSharp {
          desktop: fixed(quality: 95, width: 153) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          mobile: fixed(quality: 95, width: 136) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      scentBeautyWhite: file(
        relativePath: { eq: "ksv-scent-beauty-white.png" }
      ) {
        childImageSharp {
          fixed(quality: 95, width: 202) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      scentBeautyGold: file(relativePath: { eq: "ksv-scent-beauty-gold.png" }) {
        childImageSharp {
          desktop: fixed(quality: 95, width: 202) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          mobile: fixed(quality: 95, width: 184) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      wholesumWhite: file(relativePath: { eq: "ksv-wholesum-white.png" }) {
        childImageSharp {
          fixed(quality: 95, width: 190) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      wholesumGold: file(relativePath: { eq: "ksv-wholesum-gold.png" }) {
        childImageSharp {
          desktop: fixed(quality: 95, width: 190) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          mobile: fixed(quality: 95, width: 174) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      futureFamilyWhite: file(
        relativePath: { eq: "ksv-future-family-white.png" }
      ) {
        childImageSharp {
          fixed(quality: 95, width: 170) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      futureFamilyGold: file(
        relativePath: { eq: "ksv-future-family-gold.png" }
      ) {
        childImageSharp {
          desktop: fixed(quality: 95, width: 170) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          mobile: fixed(quality: 95, width: 158) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      odekoWhite: file(relativePath: { eq: "ksv-odeko-white.png" }) {
        childImageSharp {
          fixed(quality: 95, width: 90) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      odekoGold: file(relativePath: { eq: "ksv-odeko-gold.png" }) {
        childImageSharp {
          desktop: fixed(quality: 95, width: 90) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          mobile: fixed(quality: 95, width: 90) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      simplifyWhite: file(relativePath: { eq: "ksv-simplify-white.png" }) {
        childImageSharp {
          fixed(quality: 95, width: 190) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      simplifyGold: file(relativePath: { eq: "ksv-simplify-gold.png" }) {
        childImageSharp {
          desktop: fixed(quality: 95, width: 190) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          mobile: fixed(quality: 95, width: 169) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      caastleWhite: file(relativePath: { eq: "ksv-caastle-white.png" }) {
        childImageSharp {
          fixed(quality: 95, width: 190) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      caastleGold: file(relativePath: { eq: "ksv-caastle-gold.png" }) {
        childImageSharp {
          desktop: fixed(quality: 95, width: 190) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          mobile: fixed(quality: 95, width: 169) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);
  const isTablet = useMediaQuery(mqUtil(mqs.md));
  const companies = [
    {
      id: "simplus",
      name: "Simplus",
      imageWidth: isTablet ? 190 : 169,
      imageWhite: images.simplusWhite.childImageSharp.fixed,
      imageGold: isTablet
        ? images.simplusGold.childImageSharp.desktop
        : images.simplusGold.childImageSharp.mobile,
      description:
        "A Platinum Salesforce Partner and the industry leader in Quote-to-Cash implementations.",
      siteUrl: "https://www.simplus.com",
      badge: "2020 Acquisition",
    },
    {
      id: "clene-nanomedicine",
      name: "Clene Nanomedicine",
      imageWidth: isTablet ? 136 : 121,
      imageWhite: images.cleneWhite.childImageSharp.fixed,
      imageGold: isTablet
        ? images.cleneGold.childImageSharp.desktop
        : images.cleneGold.childImageSharp.mobile,
      description:
        "Clinical-stage biopharmaceutical company, focused on the development of unique therapeutics for neurodegenerative diseases.",
      siteUrl: "https://clene.com",
      badge: "2020 IPO",
    },
    {
      id: "the-perfumery",
      name: "The Perfumery",
      imageWidth: isTablet ? 211 : 188,
      imageWhite: images.perfumeryWhite.childImageSharp.fixed,
      imageGold: isTablet
        ? images.perfumeryGold.childImageSharp.desktop
        : images.perfumeryGold.childImageSharp.mobile,
      description:
        "Business to business solution for high quality natural aromatics, custom blends, fragrance duplication, product development and private labeling.",
      siteUrl: "https://theperfumery.com",
      badge: "",
    },
    {
      id: "atida",
      name: "Atida",
      imageWidth: 108,
      imageWhite: images.atidaWhite.childImageSharp.fixed,
      imageGold: isTablet
        ? images.atidaGold.childImageSharp.desktop
        : images.atidaGold.childImageSharp.mobile,
      description: "Largest privately owned pan-European online pharmacy.",
      siteUrl: "https://atidahealth.com",
      badge: "",
    },
    {
      id: "pattern",
      name: "Pattern",
      imageWidth: isTablet ? 153 : 136,
      imageWhite: images.patternWhite.childImageSharp.fixed,
      imageGold: isTablet
        ? images.patternGold.childImageSharp.desktop
        : images.patternGold.childImageSharp.mobile,
      description:
        "Industry leading player in ecommerce intelligence, sales optimization, marketplace management, and global expansion.",
      siteUrl: "https://pattern.com",
      badge: "",
    },
    {
      id: "health-hero",
      name: "Health Hero",
      imageWidth: isTablet ? 211 : 188,
      imageWhite: images.healthHeroWhite.childImageSharp.fixed,
      imageGold: isTablet
        ? images.healthHeroGold.childImageSharp.desktop
        : images.healthHeroGold.childImageSharp.mobile,
      description:
        "Healthhero brings together human expertise and digital convenience providing remote access to doctors and expert clinicians directly to patients, insurance-policy holders and employees.",
      siteUrl: "https://healthhero.com",
      badge: "",
    },
    {
      id: "builder-ai",
      name: "Builder.ai",
      imageWidth: isTablet ? 153 : 136,
      imageWhite: images.builderWhite.childImageSharp.fixed,
      imageGold: isTablet
        ? images.builderGold.childImageSharp.desktop
        : images.builderGold.childImageSharp.mobile,
      description:
        "Builder.ai is a leading low code AI-powered software development platform that empowers enterprises, small businesses and entrepreneurs to build, run and scale their software without requiring technical expertise and any lines of code.",
      siteUrl: "https://builder.ai/",
      badge: "",
    },
    {
      id: "tinubu-square",
      name: "Tinubu Square",
      imageWidth: isTablet ? 103 : 188,
      imageWhite: images.tinubuSquareWhite.childImageSharp.fixed,
      imageGold: isTablet
        ? images.tinubuSquareGold.childImageSharp.desktop
        : images.tinubuSquareGold.childImageSharp.mobile,
      description:
        "State of the art, end-to-end integrated, cloud SaaS platform enabling digital transformation for credit insurance & surety industry.",
      siteUrl: "https://tinubu.com/",
      badge: "",
    },
    {
      id: "scent-beauty",
      name: "Scent Beauty",
      imageWidth: isTablet ? 202 : 184,
      imageWhite: images.scentBeautyWhite.childImageSharp.fixed,
      imageGold: isTablet
        ? images.scentBeautyGold.childImageSharp.desktop
        : images.scentBeautyGold.childImageSharp.mobile,
      description:
        "Scent Beauty is a multi-brand platform for bespoke fragrances, perfumes, and colognes created in collaboration with the leading culturally relevant brands.",
      siteUrl: "https://scentbeauty.com/",
      badge: "",
    },
    {
      id: "wholesum",
      name: "Wholesum Brands",
      imageWidth: isTablet ? 190 : 174,
      imageWhite: images.wholesumWhite.childImageSharp.fixed,
      imageGold: isTablet
        ? images.wholesumGold.childImageSharp.desktop
        : images.wholesumGold.childImageSharp.mobile,
      description:
        "Seoul-based e-commerce aggregator, focused on discovering made-in-Korea brands that primarily sell on local e-commerce marketplaces across lifestyle, health, children and pet categories.",
      siteUrl: "https://wholesumbrands.com/",
      badge: "",
    },
    {
      id: "futureFamily",
      name: "Future Family",
      imageWidth: isTablet ? 110 : 158,
      imageWhite: images.futureFamilyWhite.childImageSharp.fixed,
      imageGold: isTablet
        ? images.futureFamilyGold.childImageSharp.desktop
        : images.futureFamilyGold.childImageSharp.mobile,
      description:
        "Future Family is a complete solution to fertility treatment today, offering new levels of care support with a stress-free way to pay.",
      siteUrl: "https://futurefamily.com/",
      badge: "",
    },
    {
      id: "odeko",
      name: "ODEKO",
      imageWidth: isTablet ? 110 : 158,
      imageWhite: images.odekoWhite.childImageSharp.fixed,
      imageGold: isTablet
        ? images.odekoGold.childImageSharp.desktop
        : images.odekoGold.childImageSharp.mobile,
      description:
        "Odeko is an all-in-one operations partner that helps independent businesses save what matters most: money, time, and the planet. Its smart operations software optimizes supply chain management, empowering businesses to order exactly what they need, enabling them to increase their revenue, lower their expenses, and reduce their environmental footprint.",
      siteUrl: "https://odeko.com/",
      badge: "",
    },
    {
      id: "simplify",
      name: "Simplify",
      imageWidth: isTablet ? 110 : 158,
      imageWhite: images.simplifyWhite.childImageSharp.fixed,
      imageGold: isTablet
        ? images.simplifyGold.childImageSharp.desktop
        : images.simplifyGold.childImageSharp.mobile,
      description:
        "Simplify Asset Management is an investment advisor based in New York City, New York. Established in 2020, the firm helps advisors build innovative portfolio building blocks, designed to directly solve today’s most pressing portfolio challenges.",
      siteUrl: "https://www.simplify.us/",
      badge: "",
    },
    {
      id: "caastle",
      name: "Caastle",
      imageWidth: isTablet ? 110 : 158,
      imageWhite: images.caastleWhite.childImageSharp.fixed,
      imageGold: isTablet
        ? images.caastleGold.childImageSharp.desktop
        : images.caastleGold.childImageSharp.mobile,
      description:
        "Developer of a subscription rental platform designed to permit retailers to offer clothing-as-a-service (CaaS) to their consumers. The company's platform offers technology, reverse logistics, and managed services to help retailers, brands, digital communities, and content creators participate in the new economy.",
      siteUrl: "https://www.caastle.com",
      badge: "",
    },
  ];
  const activeCompany = companies.find(
    (company) => company.id === activeCompanyId
  );
  const scrollToTopOfSection = () => {
    const investments = document.querySelector("#investments");
    const offset = investments?.getBoundingClientRect().top || 0;
    const top = window.scrollY + offset;
    window.scroll({ top, behavior: "smooth" });
  };
  return (
    <Container>
      <ContainerInner>
        <AnimFade direction="up">
          <Eyelash>Investments</Eyelash>
          <Heading>
            Current
            <span> portfolio</span>
          </Heading>
        </AnimFade>
        {isTablet ? (
          <Companies>
            <AnimFade direction="right">
              <Left>
                {companies.map((company, i) => (
                  <ImgContainer
                    key={`current-investment-${i}`}
                    onClick={() => {
                      setActiveCompanyId(company.id);
                      scrollToTopOfSection();
                    }}
                  >
                    <StyledImg
                      active={company.id === activeCompanyId}
                      width={company.imageWidth}
                      fixed={company.imageGold}
                      alt={company.name}
                    />
                    <StyledImg
                      active={company.id === activeCompanyId}
                      width={company.imageWidth}
                      fixed={company.imageWhite}
                      alt={company.name}
                    />
                  </ImgContainer>
                ))}
              </Left>
            </AnimFade>
            <Center />
            <AnimFade direction="left">
              <Right>
                <ActiveHeading>{activeCompany?.name}</ActiveHeading>
                {!!activeCompany?.badge && (
                  <ActiveBadge>{activeCompany?.badge}</ActiveBadge>
                )}
                <ActiveDescription>
                  {activeCompany?.description}
                </ActiveDescription>
                {!!activeCompany?.siteUrl && (
                  <ActiveUrl href={activeCompany?.siteUrl} target="blank">
                    Visit Website
                    <Svg.RightArrow />
                  </ActiveUrl>
                )}
              </Right>
            </AnimFade>
          </Companies>
        ) : (
          <MobileCompanies>
            {companies.map((company, i) => (
              <MobileCompany key={`mobile-company-${i}`}>
                <MobileTop>
                  <MobileImg
                    width={company.imageWidth}
                    fixed={company.imageGold}
                    alt={company.name}
                  />
                  {!!company?.badge && (
                    <ActiveBadge>2020 Acquisition</ActiveBadge>
                  )}
                </MobileTop>
                <ActiveDescription>{company?.description}</ActiveDescription>
                {!!company?.siteUrl && (
                  <ActiveUrl href={company?.siteUrl} target="blank">
                    Visit Website
                    <Svg.RightArrow />
                  </ActiveUrl>
                )}
              </MobileCompany>
            ))}
          </MobileCompanies>
        )}
      </ContainerInner>
    </Container>
  );
};
