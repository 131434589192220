import React from "react";

export const RightArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="15"
    viewBox="0 0 22 15"
  >
    <path
      d="M0 1L22 7.5 0 14 7 7.5 0 1ZM17.7 7.5L8.9 7.5 4.8 11.3 17.7 7.5Z"
      fill="currentColor"
    />
  </svg>
);
