import React from "react";
import {
  Container,
  ContainerInner,
  Heading,
  Companies,
  StyledImg,
  Company,
  Badge,
} from "./UI/PastInvestmentsUI";
import { useStaticQuery, graphql } from "gatsby";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { mqUtil, mqs } from "../styles/mqs";
import { AnimFade } from "./AnimFade";
import { SectionDots } from "./SectionDots";

export const PastInvestments = () => {
  const images = useStaticQuery(graphql`
    query {
      thriveMarket: file(relativePath: { eq: "ksv-thrive-market.png" }) {
        childImageSharp {
          desktop: fixed(quality: 95, width: 110) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          mobile: fixed(quality: 95, width: 84) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      groveCollaborative: file(
        relativePath: { eq: "ksv-grove-collaborative.png" }
      ) {
        childImageSharp {
          desktop: fixed(quality: 95, width: 103) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          mobile: fixed(quality: 95, width: 93) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      cadre: file(relativePath: { eq: "ksv-cadre.png" }) {
        childImageSharp {
          desktop: fixed(quality: 95, width: 157) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          mobile: fixed(quality: 95, width: 117) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      silvercar: file(relativePath: { eq: "ksv-silvercar.png" }) {
        childImageSharp {
          desktop: fixed(quality: 95, width: 177) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          mobile: fixed(quality: 95, width: 133) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      guardant: file(relativePath: { eq: "ksv-guardant.png" }) {
        childImageSharp {
          desktop: fixed(quality: 95, width: 179) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          mobile: fixed(quality: 95, width: 135) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      coupang: file(relativePath: { eq: "ksv-coupang.png" }) {
        childImageSharp {
          desktop: fixed(quality: 95, width: 122) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          mobile: fixed(quality: 95, width: 110) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      chowNow: file(relativePath: { eq: "ksv-chownow.png" }) {
        childImageSharp {
          desktop: fixed(quality: 95, width: 141) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          mobile: fixed(quality: 95, width: 101) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      galileo: file(relativePath: { eq: "ksv-galileo.png" }) {
        childImageSharp {
          desktop: fixed(quality: 95, width: 167) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          mobile: fixed(quality: 95, width: 143) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      dialpad: file(relativePath: { eq: "ksv-dialpad.png" }) {
        childImageSharp {
          desktop: fixed(quality: 95, width: 167) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          mobile: fixed(quality: 95, width: 143) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      eden: file(relativePath: { eq: "ksv-eden.png" }) {
        childImageSharp {
          desktop: fixed(quality: 95, width: 136) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          mobile: fixed(quality: 95, width: 110) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      upland: file(relativePath: { eq: "ksv-upland.png" }) {
        childImageSharp {
          desktop: fixed(quality: 95, width: 103) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          mobile: fixed(quality: 95, width: 88) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      branch: file(relativePath: { eq: "ksv-branch.png" }) {
        childImageSharp {
          desktop: fixed(quality: 95, width: 140) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
          mobile: fixed(quality: 95, width: 99) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);
  const isTablet = useMediaQuery(mqUtil(mqs.md));
  const companies = [
    {
      imageWidth: isTablet ? 110 : 84,
      imageAlt: "Thrive Market logo",
      image: isTablet
        ? images.thriveMarket.childImageSharp.desktop
        : images.thriveMarket.childImageSharp.mobile,
      badge: "",
    },
    {
      imageWidth: isTablet ? 103 : 93,
      imageAlt: "Grove Collaborative logo",
      image: isTablet
        ? images.groveCollaborative.childImageSharp.desktop
        : images.groveCollaborative.childImageSharp.mobile,
      badge: "2022 IPO",
    },
    {
      imageWidth: isTablet ? 157 : 117,
      imageAlt: "Cadre logo",
      image: isTablet
        ? images.cadre.childImageSharp.desktop
        : images.cadre.childImageSharp.mobile,
      badge: "",
    },
    {
      imageWidth: isTablet ? 177 : 133,
      imageAlt: "Silvercar logo",
      image: isTablet
        ? images.silvercar.childImageSharp.desktop
        : images.silvercar.childImageSharp.mobile,
      badge: "2017 Acquisition",
    },
    {
      imageWidth: isTablet ? 179 : 135,
      imageAlt: "Guardant logo",
      image: isTablet
        ? images.guardant.childImageSharp.desktop
        : images.guardant.childImageSharp.mobile,
      badge: "2018 IPO",
    },
    {
      imageWidth: isTablet ? 122 : 110,
      imageAlt: "Coupang logo",
      image: isTablet
        ? images.coupang.childImageSharp.desktop
        : images.coupang.childImageSharp.mobile,
      badge: "2021 IPO",
    },
    {
      imageWidth: isTablet ? 141 : 101,
      imageAlt: "ChowNow logo",
      image: isTablet
        ? images.chowNow.childImageSharp.desktop
        : images.chowNow.childImageSharp.mobile,
      badge: "",
    },
    {
      imageWidth: isTablet ? 167 : 143,
      imageAlt: "Galileo logo",
      image: isTablet
        ? images.galileo.childImageSharp.desktop
        : images.galileo.childImageSharp.mobile,
      badge: "2020 Acquisition",
    },
    {
      imageWidth: isTablet ? 167 : 145,
      imageAlt: "Dialpad logo",
      image: isTablet
        ? images.dialpad.childImageSharp.desktop
        : images.dialpad.childImageSharp.mobile,
      badge: "",
    },
    {
      imageWidth: isTablet ? 136 : 110,
      imageAlt: "Eden logo",
      image: isTablet
        ? images.eden.childImageSharp.desktop
        : images.eden.childImageSharp.mobile,
      badge: "",
    },
    {
      imageWidth: isTablet ? 103 : 88,
      imageAlt: "Upland logo",
      image: isTablet
        ? images.upland.childImageSharp.desktop
        : images.upland.childImageSharp.mobile,
      badge: "2017 IPO",
    },
    {
      imageWidth: isTablet ? 140 : 99,
      imageAlt: "Branch logo",
      image: isTablet
        ? images.branch.childImageSharp.desktop
        : images.branch.childImageSharp.mobile,
      badge: "",
    },
  ];
  return (
    <Container>
      <ContainerInner>
        <AnimFade direction="up">
          <Heading>
            Past
            <span> investments</span>
          </Heading>
        </AnimFade>
        <Companies>
          {companies.map((company, i) => (
            <Company key={`past-investment-${i}`}>
              <AnimFade direction="right">
                <StyledImg
                  width={company?.imageWidth}
                  fixed={company?.image}
                  alt={company?.imageAlt}
                />
                {!!company?.badge && <Badge>{company.badge}</Badge>}
              </AnimFade>
            </Company>
          ))}
        </Companies>
      </ContainerInner>
      <SectionDots />
    </Container>
  );
};
