import React, { useState, useEffect } from "react";
import {
  Container,
  ContainerInner,
  Nav,
  NavItem,
  CtaButtons,
  Logo,
  CtaContact,
  CtaLogin,
  Hamburger,
  MobileMenu,
  Close,
} from "./UI/HeaderUI";
import { Section } from "../typings/sections";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { mqUtil, mqs } from "../styles/mqs";
import * as Svg from "./svgs";
import { useSpring } from "react-spring";

type Props = {
  sections: Section[];
};

export const Header = ({ sections }: Props) => {
  const [isReady, setIsReady] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const isDesktop = useMediaQuery(mqUtil(mqs.lg));
  const navSections = sections.filter((section) => section.name !== "Contact");
  const contactSection = sections.find((section) => section.name === "Contact");
  const onScroll = (top: number) => {
    window.scroll({ top, behavior: "smooth" });
    setIsOpen(false);
  };
  const onClick = (ref?: React.RefObject<HTMLDivElement>) => {
    const offset = ref?.current?.getBoundingClientRect().top || 0;
    const top = window.scrollY + offset;
    onScroll(top);
  };
  useEffect(() => {
    const docClass = document.documentElement.classList;
    if (isOpen) {
      docClass.add("page-lock");
    } else {
      docClass.remove("page-lock");
    }
  }, [isOpen]);
  const navAnim = useSpring({
    opacity: isReady ? 1 : 0,
    transform: isReady ? "translateY(0)" : "translateY(10%)",
  });
  useEffect(() => {
    setIsReady(true);
  }, []);
  return (
    <Container>
      <ContainerInner style={navAnim}>
        <Logo onClick={() => onScroll(0)}>
          <Svg.Logo />
        </Logo>
        {isDesktop ? (
          <>
            <Nav>
              {navSections.map(({ name, ref }, i) => (
                <NavItem key={`nav-${i}`} onClick={() => onClick(ref)}>
                  {name}
                </NavItem>
              ))}
            </Nav>
            <CtaButtons>
              <CtaContact onClick={() => onClick(contactSection?.ref)}>
                {contactSection?.name}
              </CtaContact>
              <CtaLogin
                href="https://urldefense.proofpoint.com/v2/url?u=https-3A__services.sungarddx.com_Document_3780071&d=DwMF_g&c=euGZstcaTDllvimEN8b7jXrwqOf-v5A_CdpgnVfiiMM&r=YjLpI-nX56axXe5vxn-4FRO7ygzFwPZZUZC1LJu03TM&m=4JBEKXn-3HUGrpsez3yTAoRFCq6iAFmnh9YgTOmsr3M&s=2YWPetZi-YJiKOkCb0SdvQT23rDt0bbkPczSOjiBn10&e="
                target="blank"
              >
                Log In
              </CtaLogin>
            </CtaButtons>
          </>
        ) : (
          <>
            <Hamburger onClick={() => setIsOpen(!isOpen)}>
              <Svg.Hamburger />
            </Hamburger>
          </>
        )}
      </ContainerInner>
      <MobileMenu isOpen={isOpen}>
        {navSections.map(({ name, ref }, i) => (
          <NavItem key={`nav-${i}`} onClick={() => onClick(ref)}>
            {name}
          </NavItem>
        ))}
        <CtaContact onClick={() => onClick(contactSection?.ref)}>
          {contactSection?.name}
        </CtaContact>
        <CtaLogin
          href="https://urldefense.proofpoint.com/v2/url?u=https-3A__services.sungarddx.com_Document_3780071&d=DwMF_g&c=euGZstcaTDllvimEN8b7jXrwqOf-v5A_CdpgnVfiiMM&r=YjLpI-nX56axXe5vxn-4FRO7ygzFwPZZUZC1LJu03TM&m=4JBEKXn-3HUGrpsez3yTAoRFCq6iAFmnh9YgTOmsr3M&s=2YWPetZi-YJiKOkCb0SdvQT23rDt0bbkPczSOjiBn10&e="
          target="blank"
        >
          Log In
        </CtaLogin>
        <Close onClick={() => setIsOpen(false)}>
          <Svg.Close />
        </Close>
      </MobileMenu>
    </Container>
  );
};
