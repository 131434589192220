import styled from "styled-components";
import { colors } from "../../styles/colors";
import { eyelash, heading } from "../../styles/ui";
import Img from "gatsby-image";
import * as buttons from "../../styles/buttons";
import { mqs } from "../../styles/mqs";
import { fonts } from "../../styles/fonts";

type Width = { width: number };
type Active = { active: boolean };

export const Container = styled.div`
  background-color: ${colors.biscay};
  padding: 100px 0 85px;
  color: ${colors.white};
`;

export const ContainerInner = styled.div`
  margin: 0 auto;
  padding: 0 24px;
  max-width: 1168px;
  width: 100%;
`;

export const Heading = styled.h2`
  margin-bottom: 30px;
  ${heading};
  span {
    color: ${colors.curiousBlue};
  }
`;

export const Eyelash = styled.span`
  margin-bottom: 18px;
  ${eyelash};
`;

export const ImgContainer = styled.button`
  ${buttons.noStyle};
  position: relative;
  opacity: 1;
  transition: opacity 0.3s ease;
  min-height: 45px;
  &:hover {
    opacity: 0.7;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledImg = styled(Img)<Width & Active>`
  position: absolute !important;
  width: auto;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease;
  &:nth-child(1) {
    opacity: ${({ active }) => (active ? 1 : 0)};
  }
  &:nth-child(2) {
    opacity: ${({ active }) => (active ? 0 : 1)};
  }
`;

export const Companies = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
`;

export const Left = styled.div`
  display: grid;
  grid-gap: 40px;
  padding: 24px 0;
`;

export const Right = styled.div`
  padding: 24px 0 24px 18.4%;
  position: relative;
`;

export const Center = styled.div`
  width: 2px;
  height: 100%;
  background-color: ${colors.rhino};
`;

export const ActiveHeading = styled.h3`
  font-size: 32px;
  margin-bottom: 50px;
`;

export const ActiveDescription = styled.p`
  font-size: 20px;
  margin-bottom: 15px;
  line-height: 1.6;
  ${mqs.md} {
    font-size: 24px;
    margin-bottom: 45px;
  }
`;

export const ActiveUrl = styled.a`
  text-decoration: none;
  display: grid;
  grid-gap: 16px;
  align-items: center;
  grid-auto-flow: column;
  font-size: 20px;
  color: ${colors.curiousBlue};
  justify-content: flex-start;
  transition: color 0.3s ease, grid-gap 0.3s ease;
  ${mqs.md} {
    font-size: 24px;
    &:hover,
    &:focus {
      color: ${colors.lochmara};
      grid-gap: 28px;
    }
  }
`;

export const ActiveBadge = styled.span`
  font-family: ${fonts.montserrat}, serif;
  font-weight: bold;
  position: absolute;
  top: 8px;
  right: 0;
  padding: 0 11px;
  background-color: ${colors.sandrift};
  border-radius: 999px;
  height: 30px;
  font-size: 11px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: ${colors.white};
  ${mqs.md} {
    top: 28px;
    color: ${colors.biscay};
  }
`;

export const MobileCompanies = styled.div``;

export const MobileCompany = styled.div`
  margin-bottom: 64px;
`;

export const MobileTop = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const MobileImg = styled(Img)<Width>`
  width: ${({ width }) => `${width}px`};
`;
