import styled, { css } from "styled-components";
import { colors } from "../../styles/colors";
import * as buttons from "../../styles/buttons";
import { copy } from "../../styles/ui";
import { MemberName } from "./TheTeamUI";
import { mqs } from "../../styles/mqs";
import Img from "gatsby-image";

export const Container = styled.div`
  background-color: ${colors.wildSand};
  height: calc(100% - 69px);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  z-index: 1;
`;

export const ContainerInner = styled.div`
  margin: 0 auto;
  padding: 70px 24px;
  max-width: 1168px;
  width: 100%;
  display: grid;
  grid-template-columns: 132px 1fr;
  grid-template-areas:
    "image content"
    "bio bio"
    "arrows arrows";
  ${mqs.lg} {
    padding: 92px 24px;
    grid-template-columns: 169px 1fr auto;
    grid-template-areas:
      "image content arrows"
      ". bio .";
  }
`;

export const Close = styled.button`
  ${buttons.noStyle};
  position: absolute;
  top: 24px;
  right: 25px;
`;

export const MemberCopy = styled.p`
  ${copy};
  margin-bottom: 50px;
  ${mqs.lg} {
    padding-left: 66px;
  }
`;

export const MemberBio = styled.div`
  grid-area: bio;
  margin-top: 40px;
`;

const sharedArrowStyles = css`
  color: ${colors.curiousBlue};
  border: 2px solid ${colors.curiousBlue};
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 1;
  transition: opacity 0.3s ease;
  ${mqs.md} {
    &:hover {
      opacity: 0.5;
    }
  }
`;

export const PrevArrow = styled.button`
  ${buttons.noStyle};
  ${sharedArrowStyles};
`;

export const NextArrow = styled.button`
  ${buttons.noStyle};
  ${sharedArrowStyles};
`;

export const ArrowsContainer = styled.div`
  grid-area: arrows;
  ${mqs.lg} {
    padding-top: 50px;
  }
`;

export const Arrows = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  justify-content: center;
`;

export const ActiveData = styled.div`
  grid-area: content;
  padding: 24px 0 0 24px;
  ${mqs.md} {
    padding: 36px 0 0 66px;
  }
`;

export const ActiveName = styled(MemberName)`
  font-size: 32px;
  ${mqs.md} {
    font-size: 40px;
  }
  ${mqs.lg} {
    font-size: 48px;
  }
`;

export const ActiveImg = styled(Img)`
  grid-area: image;
  border-radius: 50%;
`;
