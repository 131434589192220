import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
  body {
    margin: 0;
    font-family: "Miller Text Rom";
    font-display: swap;
    font-style: normal;
    font-weight: normal;
    overflow-x: hidden;
  }
  img {
    max-width: 100%;
  }
  svg {
    display: block;
  }
  h1, h2, h3, h4, h5, h6, p {
    font-weight: normal !important;
    margin: 0;
  }
  .page-lock {
    overflow: hidden;
  }
`;
