import React, { useEffect, useState } from "react";
import AnalogClock from "react-clock";
import "react-clock/dist/Clock.css";
import styled from "styled-components";

type Props = {
  location: "boston" | "london" | "seoul";
};

const Container = styled.div`
  display: grid;
  grid-gap: 12px;
`;

const clockMap = {
  boston: { city: "Boston", timeZone: "America/New_York" },
  london: { city: "London", timeZone: "Europe/London" },
  seoul: { city: "Seoul", timeZone: "Asia/Seoul" }
};

export const Clock = ({ location }: Props) => {
  const { city, timeZone } = clockMap[location];
  const calculateTime = () => new Date().toLocaleString("en-US", { timeZone });
  const [value, setValue] = useState(new Date(calculateTime()));
  useEffect(() => {
    const interval = setInterval(
      () => setValue(new Date(calculateTime())),
      1000
    );
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <Container>
      <AnalogClock
        size={58}
        value={value}
        minuteHandWidth={2}
        minuteHandLength={63}
        hourHandWidth={2}
        hourMarksWidth={2}
        hourMarksLength={20}
        secondHandWidth={1}
        secondHandLength={75}
        renderMinuteMarks={false}
      />
      <p>{city}</p>
    </Container>
  );
};
