import { css } from "styled-components";
import { colors } from "./colors";

export const primary = css`
  white-space: nowrap;
  background-color: ${colors.curiousBlue};
  border-radius: 5px;
  height: 35px;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 25px;
  border: 0;
  outline: 0;
  color: ${colors.white};
  text-decoration: none;
  font: inherit;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;
  &:hover,
  &:focus {
    opacity: 0.7;
  }
`;

export const secondary = css`
  white-space: nowrap;
  background-color: transparent;
  border: 2px solid ${colors.curiousBlue};
  border-radius: 5px;
  height: 35px;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 25px;
  color: ${colors.white};
  text-decoration: none;
  font: inherit;
  cursor: pointer;
  opacity: 1;
  transition: border 0.3s ease, background-color 0.3s ease, opacity 0.3s ease;
  &:hover,
  &:focus {
    background: 2px solid ${colors.curiousBlue};
    background-color: ${colors.curiousBlue};
    opacity: 0.7;
  }
`;

export const noStyle = css`
  border: 0;
  background: none;
  padding: 0;
  margin: 0;
  outline: none;
  appearance: none;
  font: inherit;
  cursor: pointer;
  white-space: nowrap;
`;
