import styled from "styled-components";
import { heading, eyelash, copy } from "../../styles/ui";
import { colors } from "../../styles/colors";
import Img from "gatsby-image";
import { mqs } from "../../styles/mqs";

export const Container = styled.div`
  background-color: ${colors.vistaWhite};
  padding: 100px 0 132px;
  position: relative;
`;

export const ContainerInner = styled.div`
  margin: 0 auto;
  max-width: 1168px;
  width: 100%;
  display: grid;
  grid-gap: 0;
  ${mqs.md} {
    grid-gap: 96px 0;
  }
  ${mqs.lg} {
    grid-gap: 156px 0;
  }
  grid-template-areas:
    "content1"
    "image1"
    "content2"
    "image2"
    "content3"
    "image3";
  ${mqs.md} {
    padding: 0 24px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "content1 image1"
      "image2 content2"
      "content3 image3";
  }
`;

export const Content = styled.div`
  padding: 0 24px;
  ${mqs.md} {
    padding: 0;
  }
  &:nth-child(5) {
    margin-bottom: 0;
  }
  &:nth-child(6) {
    margin-bottom: 0;
  }
`;

export const Content1 = styled.div`
  grid-area: content1;
`;
export const Content2 = styled.div`
  grid-area: content2;
`;
export const Content3 = styled.div`
  grid-area: content3;
`;
export const Image1 = styled.div`
  padding-left: 50px;
  grid-area: image1;
`;
export const Image2 = styled.div`
  padding-right: 50px;
  grid-area: image2;
`;

export const ImgContainer = styled.div`
  margin-bottom: 68px;
  ${mqs.md} {
    height: 360px;
    margin-bottom: 0;
  }
`;

export const Image3 = styled.div`
  padding-left: 50px;
  grid-area: image3;
  ${ImgContainer} {
    margin-bottom: 0;
  }
`;

export const StyledImg = styled(Img)`
  object-fit: cover;
  object-position: center;
  margin-bottom: 156px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Heading = styled.h2`
  margin-bottom: 24px;
  ${heading};
`;

export const Eyelash = styled.span`
  margin-bottom: 18px;
  ${eyelash};
`;

export const Copy = styled.p`
  margin-bottom: 40px;
  ${copy};
`;
