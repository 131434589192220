import React from "react";

export const DownArrow = () => (
  <svg width="13px" height="22px" viewBox="0 0 13 22">
    <g stroke="none" strokeWidth="1" fill="none">
      <path
        d="M-4.5,4.5 L17.5,11 L-4.5,17.5 L2.48564894,11 L-4.5,4.5 Z M13.2,11 L4.379,11 L0.273672453,14.8200501 L13.2,11 Z"
        fill="#AD9378"
        transform="translate(6.500000, 11.000000) rotate(90.000000) translate(-6.500000, -11.000000) "
      ></path>
    </g>
  </svg>
);
